.new__inner-filter {
  padding: 50px 56px 42px;
  margin: 30px auto 80px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  color: $white-color;
  background: url("../../images/new_inner-header-lines.png") 0 0 no-repeat, url("../../images/new_inner-header-mask.png") 0 0 no-repeat;
  background-color: $new-blue2-color;
  background-size: 100%, 100%;
  max-width: 1380px;
  min-height: 306px;
  max-height: 306px;
  width: 100%;
  position: relative;

  &.journal {
    visibility: hidden;
    min-height: unset;
    max-height: unset;
    margin: 20px 0 0 0;
    padding: 0;
  }

  &.glossary {
    min-height: 131px;
    max-height: 131px;
    border-radius: 10px;
    padding: 40px 56px 42px;
  }

  .new__filter-title {
    font: 45px/53px $font-europa;
    font-weight: bold;
    color: $new-black-color;
    text-align: left;
  }

  .new__inner-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px 56px;
    background: $new-blue2-color;

    .new__inner-search {
      width: 50%;
      max-width: 925px;

      #mobile-filter-submit {
        display: none;
      }

      h1 {
        text-align: left;
        max-width: 676px;
        font-size: 62px;
        line-height: 81px;
        color: $new-black-color;

        span {
          color: $new-blue-color;
        }
      }

      p {
        font-size: 32px;
        line-height: 41px;
        color: $new-black-color;
        text-align: left;
        padding-bottom: 58px;
      }

      .start-learning__search {
        margin-left: 0;
        padding: 0;
        background: none;
      }

      .start-learning__search-input {
        width: calc(100% - 141px);
        max-width: 679px;
        box-shadow: 5px 10px 30px rgba(32, 33, 36, 0.11);
        height: 51px;

        input[type="text"] {
          border-radius: 10px;
          width: 100%;
          font: 18px/24px $font-europa;
        }
      }

      .start-learning__search-submit {
        width: 99px;

        input[type="submit"] {
          border: 1px solid $new-black-color;
          background: $new-black-color;
          color: $white-color;
          border-radius: 10px;
          padding: 0;
          margin: 0 11px;
          font-size: 17px;
          line-height: 24px;
          font-weight: lighter;
          white-space: nowrap;
          text-indent: unset;
          height: 51px;
          width: 84px;
        }
      }
    }
  }

  .expand_filter {
    display: inline-block;
    max-width: 88px;
    text-align: center;
    position: absolute;

    a {
      font-size: 18px;
      line-height: 24px;
      text-decoration: underline;
    }

  }

  .cc-select {
    z-index: 10;
  }


  .expand_filter {
    #toggle_filter_mobile, #close-filter-mobile {
      display: none;
    }
  }
}

@media (max-width: 1550px) {
  .new__inner-filter {
    background-size: 114%, 114%;

    .new__inner-bottom .new__inner-search {
      width: 60%;
    }
  }
}

@media (max-width: 1279px) {
  .new__inner-filter {
    background-size: 133%, 133%;

    .new__inner-bottom .new__inner-search {
      width: 70%;
    }
  }
}


@media (max-width: 1099px) {
  .new__inner-filter {
    background-size: 175%, 175%;

    .new__inner-bottom .new__inner-search {
      width: 80%;
    }
  }
}

@media (max-width: 991px) {
  .new__inner-filter {
    padding: 50px 36px 36px;
    margin: 30px auto 100px;
    background: $new-blue-light-color;
    min-height: 243px;
    max-height: 243px;

    .active_filters {
      bottom: -75px;
    }

    .new__filter-title {
      font: 52px/68px $font-europa;
      font-weight: bold;
    }

    .new__filter.active {
      bottom: -30px;
    }

    .new__inner-bottom {
      padding: 0 36px 60px 36px;
      bottom: -50px;
      background: $new-blue-light-color;

      .new__inner-search {
        width: 100%;

        .start-learning__search {
          max-width: unset;
        }

        #mobile-filter-submit {
          display: inline-block;
          background: url("../../images/icons/new_search_logo.svg") center center no-repeat;
          background-size: 23px 24px;
          border: none;
        }

        .start-learning__search-input {
          width: 100%;
          max-width: unset;
        }

        .start-learning__search-submit {
          position: absolute;
          width: 60px;
          right: 70px;

          input[type="submit"] {
            display: none;
          }

          #mobile-filter-submit {
            display: block;
          }
        }

      }
    }

    .expand_filter {
      display: block;
      background: $new-blue2-color;
      margin-top: 15px;
      max-width: unset;
      margin-left: 0;
      left: 36px;
      right: 36px;
      bottom: -32px;
      text-align: left;
      border-radius: 10px;
      position: absolute;
      z-index: 25;

      &.active {
        &:after {
          display: none;
        }

        #close-filter-mobile {
          display: block;
          position: absolute;
          top: 50%;
          right: 60px;
          transform: translateY(-50%);
          font: 18px/23px $font-europa;
          font-weight: bold;

          &:after {
            position: absolute;
            content: url("../../images/icons/new_close_mobile_menu_icon.svg");
            top: 0;
            right: -25px;
          }
        }
      }

      &:after {
        position: absolute;
        content: url("../../images/icons/new_mobile_browse_icon.svg");
        top: 50%;
        right: 31px;
        transform: translateY(-50%);
        pointer-events: none;
      }

      #toggle_filter_mobile {
        color: $new-black-color;
        text-decoration: none;
        font: 24px/36px $font-europa;
        padding: 16px 36px;
        display: block;
      }

      #toggle_filter, #close-filter-mobile {
        display: none;
      }
    }
  }
}
