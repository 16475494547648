.start-learning {
  padding: 60px 0 60px;
  margin: 0 auto 80px;
  text-align: center;
  color: $white-color;
  background: url("../../images/banner.jpg") center center no-repeat;
  background-size: cover;
  max-width: 1660px;
  min-height: 360px;
  width: 100%;

  p {
    font-size: 16px;
  }
}

.start-learning__search {
  margin: 10px auto 0px;
  max-width: 820px;
  background: $white-color;
  border-radius: 6px;
  color: $main-color;
  padding: 0 0 0 6px;
  text-align: left;

  form, .no-form-wrapper {
    font-size: 0;
    vertical-align: top;
  }
}

.start-learning__search-categories {
  width: 23%;
  display: inline-block;
  border-right: 1px solid $gray1-color;
  vertical-align: top;
  height: 70px;

  select {
    padding: 20px 43px 23px 24px;
    background: url("../../images/icons/arrow_b_b.svg") right 25px center no-repeat;
    background-size: 8px 5px;
    font-size: 16px;
    border: 1px solid $white-color;
    appearance: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    width: 100%;

    &::-ms-expand {
      display: none; /* IE */
    }
  }
}

.start-learning__search-input {
  display: inline-block;
  //width: calc(77% - 60px);
  width: calc(100% - 60px);
  vertical-align: top;
  height: 70px;

  input[type="text"] {
    border: none;
    font-size: 24px;
    padding: 16px 30px 20px;
    height: 100%; // safari fix
  }
}

.start-learning__search-submit {
  display: inline-block;
  width: 60px;
  vertical-align: top;

  input[type="submit"] {
    width: 100%;
    height: 70px;
    font-size: 16px;
    background: url("../../images/icons/search.svg") center center no-repeat;
    background-size: 32px 32px;
    border: none;
    text-indent: -9999px;

    &:hover {
      background: url("../../images/icons/search_g.svg") center center no-repeat;
      background-size: 32px 32px;
    }
  }
}

@media screen and (max-width: 1419px) { /* 1281-1419 */

  .start-learning {
    padding: 50px 0 60px;
    min-height: 300px;
    margin: 0 auto 50px;
  }

}

@media screen and (max-width: 1199px) { /* 992-1199 */

  .start-learning {
    padding: 40px 0 60px;
  }

}

@media screen and (max-width: 991px) { /* 991-768 */

  .start-learning__search-categories {
    width: 26%;
  }

  .start-learning__search-input {
    width: calc(74% - 60px);
  }

  .start-learning__search-input input[type="text"] {
    font-size: 22px;
    padding: 17px 30px 21px;
  }


}


@media screen and (max-width: 767px) {

  .start-learning__search {
    padding: 0 6px 0 6px;
  }

  .start-learning__search-categories {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid $gray1-color;
  }

  .start-learning__search-categories select {
    padding: 18px 43px 18px 24px;
  }

  .start-learning__search-input {
    width: calc(100% - 60px);
    height: auto;
  }

  .start-learning__search-input input[type="text"] {
    font-size: 20px;
    padding: 18px 30px 18px;
  }

  .start-learning__search-submit input[type="submit"] {
    height: 65px;
  }

  .start-learning {
    padding: 30px 0 40px;
    min-height: 200px;
    margin: 0 auto 30px;

    h1 {
      padding: 0 0 10px 0;
    }

    p {
      padding: 0 0 10px 0;
    }

  }

}

@media screen and (max-width: 479px) {

  .start-learning__search-categories select {
    padding: 18px 33px 18px 14px;
  }

  .start-learning__search-input input[type="text"] {
    font-size: 18px;
    padding: 18px 16px 18px;
  }

}

@media screen and (max-width: 410px) {

  .start-learning {
    margin: 0 auto 30px;
  }

  .start-learning__search-input input[type="text"] {
    font-size: 16px;
  }

  .start-learning__search-submit input[type="submit"] {
    height: 58px;
  }

}

