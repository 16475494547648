.preferences {
  .tag {
    margin-right: 5px;
    position: relative;
    padding-right: 15px;
    vertical-align: 10px;
  }

  .remove-tag {
    position: absolute;
    margin-bottom: 5px;
    top: 0;
    right: 5px;
    cursor: pointer;
  }

  a {
    margin-top: 5px;
    font-size: 16px;
    color: $blue-color;
    cursor: pointer;
    display: block;
  }
}
