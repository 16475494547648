.cc-table {
  border-spacing: 2rem 0;
  border-collapse: separate;

  td {
    padding: .5rem 0;
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid $gray2-color;
  }

  thead td {
    font-weight: bold;
    border-bottom: 3px solid $gray2-color;
  }
}
