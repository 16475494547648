.sup__below-filters {
  width: 100%;
  color: #fff;
  line-height: 250px;
  text-align: center;
  padding-top: 20px;
  position: relative;

  img {
    left: 0;
    width: 100%;
    border-radius: 6px;
    vertical-align: top;
  }

  .html {
    color: black;
    max-width: 100%;
  }
}

.sup__below-readings {
  width: 100%;
  padding: 10px;
  text-align: center;
  max-height: 500px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}

.html {
  color: black;
  width: 100%;
  max-height: 500px;
  font-size: 16px;
  border-radius: 6px;
  padding: 10px;

  img {
    border-radius: 6px;
  }
}
