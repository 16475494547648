.faq {
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $gray1-color;
  }
}

.faq.opened {
  .faq__title:after {
    background-image: url("../../images/collapse.png");
  }
}

.faq__title {
  position: relative;
  cursor: pointer;
  min-height: 32px;
  padding: 0 42px 0 0;

  &:after {
    content: '';
    background-image: url("../../images/expand.png");
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.faq__content {
  display: none;
}

.faq__content__inner {
  padding: 12px 0;
}
