.post {
  overflow: hidden;

  &.post--article {
    max-width: 850px;
    margin: 0 auto;
  }

  .row {
    margin-left: -30px;
  }

  .col {
    padding-right: 30px;
    padding-left: 30px;
  }

  p {
    font-size: 18px;
  }

  ul, ol {
    li {
      font-size: 18px;

      &:before {
        top: 10px;
      }
    }
  }

}

.post__publication:first-letter {
  text-transform: uppercase;
}

.post__buy-choises .continue-reading {
  font-size: 18px;
  margin-top: 1.5em;
}

.login-or-register {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 18px;
}

.login-to-read {
  text-align: center;
  color: #b3b3b3;
}

.or-divider {
  width: 100%;
  text-align: center;
  position: relative;

  &:after {
    content: " ";
    position: absolute;
    height: 1px;
    background: #cdcdcd;
    left: 53%;
    right: -16px;
    top: 11px;
  }

  &:before {
    content: " ";
    position: absolute;
    height: 1px;
    background: #cdcdcd;
    left: -16px;
    right: 53%;
    top: 11px;
  }

  p {
    display: inline-block;
  }
}

.post__meta-info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 35px;

  &.mobile {
    display: none;
  }

  .post__buy-price, .post__subscription-info {
    width: 48%;
    text-align: center;
  }

  .gray-text {
    color: #b3b3b3;
    margin: 0.5em 0 0 0;
  }

  .price-text {
    font-size: 32px;
    margin: 0;
  }
}

.post__title {
  h1 {
    padding-bottom: 15px;
  }

  padding-bottom: 34px;
}

.post__languages {
  text-transform: uppercase;
}

.post__info {
  h1, .h1 {
    line-height: 1.25;
    font-size: 39px;
  }

  .secondary-title {
    line-height: 1.25;
    font-size: 22px;
    font-weight: bold;
    font-family: $font-europa;
  }

  ul {
    margin: 0;

    li {
      padding: 11px 0 12px 0;
      border-top: 1px solid $gray1-color;

      &:first-child {
        border: none;
      }

      &:before {
        display: none;
      }

      span {
        font-size: 14px;
        background: $gray1-color;
        border-radius: 10px;
        color: $gray3-color;
        display: inline-block;
        padding: 0px 8px 2px;
      }

      a:hover span {
        color: $blue1-color;
      }

      .icon {
        width: 20px;
        height: 24px;
      }
    }
  }
}

.post__review {
  padding: 20px 0 15px;
  margin: 0px 0 20px;
  border-top: 1px solid $gray1-color;
  border-bottom: 1px solid $gray1-color;
  font-size: 14px;

  p {
    padding: 0;
    font-size: 14px;

    &:before {
      content: ' ';
      display: inline-block;
      width: 13px;
      height: 12px;
      background: url("../../images/icons/qoute.svg") center center no-repeat;
      margin: 3px 5px 0 0;
      vertical-align: top;
    }
  }

}

.post__review-info {
  color: $gray-color;
  padding: 12px 0 0 0;

  a {
    margin: 0 0 0 20px;
  }
}

.post__video {
  border-radius: 6px;
  position: relative;
  margin: 0 0 60px;

  img {
    border-radius: 6px;
  }

  .overlay {
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue-color;
    opacity: 0.45;
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../images/icons/play.svg") center center no-repeat;
  }

}

.lp-price {
  text-align: left;
  display: block;
  font: 38px/160% $font-europa;
  font-weight: bold;
}

.post__buy-holder {
  padding: 0;
  margin: 0 0 50px;
  position: relative;

  //&:before {
  //  content: ' ';
  //  background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 79.48%);
  //  height: 134px;
  //  display: inline-block;
  //  top: -160px;
  //  left: 0;
  //  width: 100%;
  //  position: absolute;
  //}
}

.post__buy {
  padding: 30px 20px;
  margin: 0 0 80px;
  border-radius: 6px;
  background: $gray1-color;
  text-align: center;
  font-size: 24px;

  &-currency {
    float: right;
  }

  p {
    padding: 0;
  }

  .btn {
    margin: 0 0 0 25px;
    font-size: 24px;
    border-radius: 30px;
    padding: 10px 30px 14px 30px;
  }

}

.post__buy-choises {
  padding: 50px 6%;
  border-radius: 6px;
  background: $gray5-color;

  &.article-show {
    padding: 20px 2%;
  }

  p {
    font-size: 16px;
    padding: initial;
    margin: 1em 0;
  }
}

.lp-prices {
  display: flex;
  .post__buy-old-price {
    padding-left: 0.5em;
  }
}

.post.post--article {
  .post__buy-choises-info {
    flex-direction: row;

    .btn--cart {
      padding: 0;
    }
  }
}

.post__buy-choises-info {
  font-size: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  &.article-show {
    align-items: center;

    .post__buy-choises-prices {
      margin-bottom: 0;
    }
  }

  .post__buy-choises-prices {
    margin-bottom: 20px;
  }

  hr {
    border: none;
    border-left: 1px solid #333333;
    height: 60px;
    width: 1px;
    margin: 0 40px 0 45px;
  }
}

.post__buy-choises-info-left {
  width: 48%;
  display: flex;
  flex-direction: column;
  padding-right: 0;
}

.post__buy-choises-info-right {
  display: flex;
  align-items: center;
  padding: 0;
  flex-direction: column;
  width: 48%;
}

.post__buy-choises-prices {

  .form-element--radio {
    padding: 19px 0 0 0;

    input {
      display: none;
    }

    label {
      font-family: $font-europa;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0;
      margin: 0 30px 0 0;
      position: relative;
    }

    .checkmark {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background: $white-color;
      border: 1px solid $gray2-color;
      border-radius: 50%;
    }

    input:checked ~ .checkmark {
      border: 7px solid $green-color;
    }
  }

  .btn {
    font-size: 15px;
    padding: 14px 15px 15px;
    min-width: 100%;
    border-radius: 10px;
    font-weight: normal;
  }
}

.post__buy-choises-links {
  padding: 0;
  float: left;

  .btn--cart {
    padding: 0;
  }

  a {
  }

  .btn--empty {
    width: 100%;
    margin-bottom: 20px;
  }

  .btn--watch {
    max-width: 130px;
  }

}

.post__buy-choises-text {
  max-width: 56%;
  padding: 0 15px 0 0;
  margin: -3px 0 0 0;

  p {
    padding: 0;
  }
}

.post__buy-choises-subscribe {
  display: flex;

  .dropdown {
    display: inline-block;
  }

  .dropdown > a {
    color: $blue1-color;

    &:hover {
      color: $green-color;
    }
  }

  .dropdown-item {
    font-size: 16px;
  }

  > p, > div {
    font-size: 16px;
    padding: 0;
    margin-right: 10px;
  }

  .btn {
    font-size: 15px;
    padding: 16px 15px 17px;
    min-width: 200px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15), 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    font-weight: normal;

    &:not(:first-child) {
      margin-left: 25px;
    }

    &:hover {
      box-shadow: none;
    }
  }
}


.post__buy-notify {
  text-align: center;

  p {
    font-size: 16px;
  }

  .notify {
    color: $red-color;
    position: relative;
    padding: 0 0 0 20px;

    .icon {
      position: absolute;
      top: 1px;
      left: 0;
      width: 16px;
    }
  }

  .btn {
    margin-left: 10px;
  }
}

.post__reviews {
  margin: 0 0 100px;
}

.post__reviews-list {
  padding: 0px 0 5px;
  margin: -15px 0 0 0;
}

.post__reviews-item {
  padding: 25px 0 20px;
  border-top: 1px solid $gray1-color;

  &:first-child {
    border: none;
  }

  p {
    padding: 0;

    &:before {
      content: ' ';
      display: inline-block;
      width: 13px;
      height: 12px;
      background: url("../../images/icons/qoute.svg") center center no-repeat;
      margin: 7px 8px 0 0;
      vertical-align: top;
    }
  }
}

.post__reviews-info {
  font-size: 14px;
  color: $gray3-color;
  padding: 0 0 12px;
}

.post__thumbnail-holder {
  display: flex;
  padding-bottom: 0;
}

.post__thumbnail {
  display: inline-block;
  margin: 0 auto;

  img {
    max-height: 300px;
  }

  .post__thumbnail-description {
    font-size: 12px;
    color: $gray3-color;
    padding: 0 0 10px;
    text-align: right;
  }
}

@media screen and (max-width: 1226px) {
  .post__buy-choises-info-right {
    padding: 0 0 0 15px;
  }

  .or-divider {
    &:before {
      left: -30px;
    }

    &:after {
      right: -30px;
    }

  }

  .post__buy-choises-prices {
    .form-element--radio {
      padding: 10px 0 0 0;

      label {
        margin: 0 10px 0 0;
        font-size: 14px;
      }
    }

    .btn {
      margin: 0 10px 0 0;
      padding: 12px 10px 12px;
      min-width: 100%;
      font-size: 15px;
    }
  }

  .post__buy-choises-subscribe {
    max-width: unset;

    .btn {
      margin: 10px 10px 0 0;
      padding: 9px 10px 10px;
      //min-width: 0;
      font-size: 15px;
      max-width: 100%;
    }

    > p, > div {
      margin-right: 5px;
      font-size: 14px;
      max-width: 210px;
    }
  }

  .post__buy-choises {
    &.article-show {
      padding: 30px;
    }
  }

  .post__buy-choises-links {
    .btn--cart {
      line-height: 1;

      span {
        font-size: 16px;
      }
    }
  }

  .post__buy-choises-info {
    padding: 20px 0 0 0;
  }

  .post__purchase-info {
    font-size: 15px;
  }
}

@media screen and (max-width: 884px) {
  .post__buy-choises-text {
    max-width: 270px;
  }

  .post__thumbnail {
    text-align: center;
  }
}

@media screen and (max-width: 991px) { /* 991-768 */
  .post__buy-choises .post__buy-price {
    font-size: 36px;
  }

  .post__buy-choises-info .post__buy-choises-prices.mr-15 {
    margin-right: 0;
  }

  .post__buy-choises .post__buy-old-price {
    font-size: 18px;
  }

  .post.post--article {
    .post__buy-choises-info-right {
      align-items: center;
    }
  }

  .course__video-holder .post__buy-choises-info-right {
    width: 100%;
  }

  .post__buy-choises-info-right {
    align-items: flex-start;
    padding: 0;

    .post__buy-choises-links {
      width: 100%;
    }

    .post__buy-choises-prices {
      width: 100%;

      .btn {
        padding: 12px 10px 12px;
      }
    }

    .btn.btn--start {
      width: 100%;
    }
  }

  .post__info {
    margin: 0 0 0;
  }

  .post__video {
    margin: 0 0 30px;
  }

  .post__buy {
    margin: 0 0 50px;
  }

  .post__reviews {
    margin: 0 0 60px;
  }

  .post__buy-holder {
    margin: 0 0 10px;
    padding: 10px 0 0 0;
  }

  .post__thumbnail {
    text-align: center;
  }

  .post__thumbnail-holder {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .post__buy .subscribe-btn {
    margin: 15px 15px 0 15px;
  }

  .free-unlock {
    width: 100% !important;
  }

  .post__buy-choises {
    padding: 30px 30px 40px;
  }

  .post.post--article .post__buy-choises-info {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .post__buy-choises-info-left {
    width: 100%;
  }

  .post.post--article .post__buy-choises-info-right {
    width: 100%;
    margin-top: 15px;
  }

  .post__meta-info {
    padding-bottom: 0;

    &.desktop {
      .post__buy-price {
        display: none;
      }

      .post__subscription-info {
        width: 100%;
      }
    }

    &.mobile {
      display: block;
      width: 100%;

      .post__buy-price {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 599px) {

  .post.post--article {
    .post__buy-choises-subscribe .btn {
      min-width: 0;
    }
  }

  .post__buy-choises-text {
    max-width: 100%;
  }

  .post__buy-choises-info {
    padding: 0;
  }

  .or-divider {
    &:before {
      right: 56%;
    }

    &:after {
      left: 56%;
    }
  }
}


@media screen and (max-width: 479px) {
  .post__buy .subscribe-btn {
    margin: 15px 15px 0 15px;
  }

  .login-or-register {
    padding-left: 0;
  }

  .post__buy-choises-info {
    flex-direction: column;
  }

  .post__buy-choises-links {
    margin: 0 auto;
    width: 100%;

    &.article-show {
      width: unset;
    }

    .btn {
      font-size: 15px;
    }
  }

  .post__buy-choises-prices {
    margin-bottom: 15px;
  }

  .post__buy .subscribe-btn {
    margin: 15px 0px 0 0px;
    display: block;
  }

  .post__info h1,
  .post__info .h1 {
    font-size: 29px;
    line-height: 1.0;
  }

  .post__buy {
    font-size: 22px;
  }

}

.share-links {
  padding: 10px 5px;

  a:not(:last-child) {
    margin-right: 10px;
  }

  .icon {
    width: 25px;
    height: 25px;
    background-size: cover;
  }
}
