* {
  outline: none;
}

body {
  font-family: $font-europa;
  font-weight: normal;
}

a {
  color: $main-color;
  transition: color .2s ease-out;

  &:hover {
    text-decoration: none;
    color: $blue1-color;
  }

  &:active,
  &:focus {
    text-decoration: none;
  }

}

h1, .h1 {
  font-family: $font-europa;
  letter-spacing: -0.02em;
  font-weight: bold;
  font-size: 48px;
  margin: 0;
  padding: 0 0 20px 0;
}

h2 {
  font-family: $font-europa;
  letter-spacing: -0.02em;
  font-weight: bold;
  font-size: 26px;
  margin: 0;
  padding: 0 0 25px 0;
}

h3 {
  font-family: $font-europa;
  letter-spacing: -0.02em;
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  padding: 0 0 15px 0;

  &.success {
    color: $blue1-color;
  }
}

h4 {
  font-family: $font-europa;
  letter-spacing: -0.01em;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  line-height: 1.35;
}

h5 {
  font-family: $font-europa;
  letter-spacing: -0.01em;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  line-height: 1.375;
}

h6 {
  font-size: 14px;
  font-family: $font-europa;
  letter-spacing: -0.01em;
  font-weight: bold;
  margin: 0;
  padding: 0 0 14px 0;
}

p {
  padding: 0 0 18px 0;
  margin: 0;
}

strong {
  font-family: $font-europa;
  font-weight: bold;
  letter-spacing: -0.01em;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  padding-right: 58px;
  padding-left: 58px;
  width: 100%;
}

.col {
  padding-right: 20px;
  padding-left: 20px;
}

.row {
  margin-right: -20px;
  margin-left: -20px;
}

.col-pd-70 {
  padding-right: 70px;
  padding-left: 70px;
}

.col-right {
  float: right;
  width: 80%;
}

.col-left {
  float: left;
  width: 20%;
}

.col-50 {
  width: 50%;
}

.col-35 {
  width: 35%;
}

.col-45 {
  width: 45%;
}

.col-55 {
  width: 55%;
}

.col-65 {
  width: 65%;
}

.clear {
  clear: both;
}

.wrapper {
  position: relative;
  padding: 100px 0 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.display-on-mobile {
  display: none;
}

.hide-on-mobile {
  display: block;
}

@media screen and (min-width: 1380px) { /* 1380 counts */

  .container {
    width: 1380px;
  }

}

@media screen and (max-width: 1279px) { /* 1200-1280 */

  .col-left {
    width: 24%;
  }

  .col-right {
    width: 76%;
  }

  .col-50 {
    width: 50%;
  }

}

@media screen and (max-width: 1199px) { /* 992-1199 */

  h5 {
    font-size: 15px;
  }

}

@media screen and (max-width: 991px) { /* 991-768 */

  .display-on-mobile {
    display: block;
  }

  .hide-on-mobile {
    display: none;
  }

  .col-right {
    width: 100%;
  }

  .col-left {
    width: 100%;
    padding-bottom: 30px;
  }

  .col-50 {
    width: 100%;
  }

  .col-65 {
    width: 100%;
  }

  .wrapper {
    padding: 66px 0 0 0;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 22px;
  }

}

@media screen and (max-width: 479px) {
  .container {
    padding-right: 36px;
    padding-left: 36px;
  }

  .col-pd-70 {
    padding-right: 36px;
    padding-left: 36px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }

}
