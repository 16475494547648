.new__filter {
  width: 100%;
  height: 0;
  max-height: 0;
  background: $new-blue2-color;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  z-index: 10;
  border-radius: 0 0 10px 10px;
  transition: 0.2s max-height, height;
  overflow: hidden;
  padding: 0 78px;

  #inner__filter-market {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #A5C1D9;
    padding: 9px 53px 9px 28px;
    color: rgba(0, 0, 0, 0.5);
    font: 16px/20px $font-europa;
    border-radius: 10px;
    transform: translateY(100%);
    margin: 0 15px;
    z-index: 5;

    &:after {
      content: url("../../images/icons/new_remove_filter_icon.svg");
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
    }
  }

  form .checkboxes label {
    &:after {
      border: none;
    }
  }

  .filter_actions {
    position: absolute;
    top: 48px;
    right: 78px;
  }

  &.active {
    max-height: 402px;
    height: 402px;
    padding: 48px 78px 0 78px;
    overflow: unset;

    .col-md-1 {
      float: right;
    }
  }

  .filter__block-formline label {
    text-align: left;
    color: $new-black-color;
    font: 15px/20px $font-europa;
    font-weight: bold;
    white-space: nowrap;
  }

  .cc-select__options li span {
    font: 20px/26px $font-europa;
    font-weight: bold;
  }

  .cc-select {
    input[name="topic"] {
      border-radius: 10px;
      color: #000000;
      padding: 11px 15px 11px;
      background: $new-input-color;
      border: solid 1px $new-input-color;
      position: relative;
      font: 18px/24px $font-europa;
    }

    .cc-select__options {
      background: $new-filter-color;
      color: #000000;
      text-align: left;
      font: 16px/16px $font-europa;
      top: 0;
      z-index: -1;
      padding-top: 60px;
      border-radius: 10px;
    }

    &:after {
      content: url("../../images/icons/new_dropdown_arrow_light.png");
      position: absolute;
      display: inline-block;
      width: 15px;
      height: 20px;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .filter__block-formline {
    border-top: none;

    .toggle-markets {
      width: 100%;
      font-size: 16px;
      position: relative;
      z-index: 7;

      input[name="markets-toggle"] {
        border-radius: 10px;
        color: #000000;
        padding: 11px 15px 11px;
        background: #F2F9FD;
        border: solid 1px #F2F9FD;
        font: 18px/24px $font-europa;
      }

      &:after {
        content: url("../../images/icons/new_dropdown_arrow_light.png");
        position: absolute;
        display: inline-block;
        width: 15px;
        height: 20px;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  #market-checkboxes {
    display: none;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 315px;
    background: $new-filter-color;
    overflow: unset;
    position: absolute;
    top: 214px;
    left: 72px;
    text-align: left;
    border-radius: 10px;
    z-index: 6;
    right: unset;
    padding: 73px 33px 27px 28px;

    #save-markets {
      position: absolute;
      right: 32px;
      bottom: 32px;
      background: $new-blue-color;
      color: #FFFFFF;
      font: 20px/26px $font-europa;
      font-weight: normal;
      padding: 14px 28px;
      border-radius: 10px;
    }

    label {
      padding: 0 0 0 48px;
      max-width: 232px;
      color: #000000;

      .checkmark {
        width: 22px;
        height: 22px;

        &:after {
          left: 4px;
          top: -1px;
        }
      }
    }
  }

  .cc-select.display-x {
    .remove_input_value {
      display: block;
    }

    &:after {
      display: none;
    }
  }

  .remove_input_value {
    background: url("../../images/icons/new_input_x_icon.svg") 0 0 no-repeat;
    width: 15px;
    height: 15px;
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
  }

  .toggle-markets.focused {
    .remove_input_value {
      display: block;
    }

    &:after {
      display: none;
    }
  }

  #languages-checkboxes {
    overflow: unset;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;

    label {
      font: 16px/20px $font-europa;
      font-weight: normal;
    }
  }

  form .checkboxes label {
    padding: 5px 0 15px 48px;
    font: 16px/20px $font-europa;
    font-weight: normal;

    .checkmark {
      width: 30px;
      height: 30px;
      border-radius: 5px;

      &:after {
        border: none;
      }
    }
  }

  .ml-72 {
    margin-right: 72px;
  }

  .max-w-226 {
    max-width: 226px;

    &.float-right {
      float: right;
    }
  }

  form .checkboxes label .checkmark:after {
    content: url("../../images/icons/new_checkbox_checked_icon.svg");
    left: 7px;
    top: 4px;
    border: none;
    border-width: unset;
    transform: unset;
  }

  .new__other_filters {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 420px;
  }

  #filter-form .columns {
    padding: 10px 0 35px 0;

    .one-col input {
      border-radius: 10px;
      color: #000000;
    }
  }

  #close-filter {
    position: relative;
    text-decoration: underline;
    color: $new-black-color;
    font: 16px/18px $font-europa;
    font-weight: bold;
    transition: color .2s ease-out;
    display: block;
    padding-right: 35px;

    &:hover {
      color: $blue1-color;
    }

    &:after {
      content: url("../../images/icons/new_x_icon.svg");
      position: absolute;
      right: 7px;
      top: 0;
    }
  }

  .new__filter-bottom {
    display: flex;
    position: absolute;
    bottom: -315px;
    right: 0;
    font: 18px/23px $font-europa;
    font-weight: bold;

    .filter__block-formline.filter__block-formline--reset.text-right {
      width: 161px;
      padding-right: 42px;
      margin-top: 10px;
      text-decoration: underline;
      color: $new-black-color;
      font: 16px/18px $font-europa;
      font-weight: bold;
    }
  }

  .new__filter-btn-search {
    background: $new-blue-color;
    color: #FFFFFF;
    font: 18px/24px $font-europa;
    font-weight: lighter;
    padding: 13px 25px;
    border-radius: 10px;
  }
}

.active_filters {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: black;
  background: $new-blue2-color;
  padding: 22px 78px;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(100%);
  z-index: 5;
  transition: bottom 0.2s;

  .left-block {
    float: left;
    width: 150px;

    h4 {
      font: 20px/26px $font-europa;
      font-weight: bold;
    }
  }

  .center-block {
    margin-left: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .active-filter {
      background: #A5C1D9;
      padding: 9px 53px 9px 28px;
      color: rgba(0, 0, 0, 0.5);
      font: 16px/20px $font-europa;
      border-radius: 10px;
      position: relative;
      margin-right: 24px;

      &:after {
        content: url("../../images/icons/new_remove_filter_icon.svg");
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        height: 20px;
      }
    }
  }

  .right-block {
    float: right;
    display: flex;
    align-items: center;
    width: 260px;

    .reset {
      font: 16px/20px $font-europa;
      font-weight: bold;
      text-decoration: underline;
    }

    .open-filters {
      display: block;
      background: $new-blue-color;
      color: #FFFFFF;
      font: 18px/24px $font-europa;
      font-weight: lighter;
      padding: 13px 28px;
      border-radius: 10px;
      margin-left: 37px;
    }
  }
}

@media (max-width: 1630px) {
  .new__filter {
    #market-checkboxes {
      max-height: 400px;
    }
  }
}

@media (max-width: 1550px) {
  .new__filter.active {
    #filter-form > div > div:nth-child(2) {
      margin-right: 15px;
    }

    .max-w-226 {
      &.float-right {
        float: right;
      }
    }
  }
}

@media (max-width: 1366px) {
  .new__filter {
    .filter__block-formline label {
      white-space: unset;
    }

    .max-w-226 {
      max-width: 175px;
    }

    #market-checkboxes {
      top: 237px;
    }

    form .checkboxes label {
      padding: 5px 0 15px 38px;
    }
  }
}

@media (max-width: 1125px) {
  .new__filter {
    #market-checkboxes {
      max-height: 460px;
    }

    #filter-form > div > div.col-md-3.new__other_filters.ml-72 > div:nth-child(3) {
      max-width: 145px;
    }
  }
}

@media (max-width: 1063px) {
  .new__filter #market-checkboxes {
    top: 260px;
  }
}

@media (max-width: 991px) {
  .new__filter.active {
    width: unset;
    left: 36px;
    right: 36px;
    background: $new-input-color;
    padding: 60px 24px;

    #market-checkboxes {
      left: 20px;
      right: 20px;
      max-height: unset;
      flex-direction: row;

      label {
        min-width: 266px;
        max-width: 266px;
      }
    }

    #filter-form > div > div:nth-child(2) {
      margin-right: 0;
    }

    .max-w-226 {
      float: left;
      max-width: unset;
    }

    .filter_actions {
      position: relative;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px 0;

      .new__filter-bottom {
        bottom: 0;
      }
    }

    .col-md-1 {
      float: unset;
    }

    .cc-select input[name="topic"] {
      background: #FFFFFF;
    }

    .ml-72 {
      margin-right: 0;
    }

    .filter__block-formline .toggle-markets input[name="markets-toggle"] {
      background: #FFFFFF;
    }

    #languages-checkboxes {
      max-height: unset;
    }

    form .checkboxes label .checkmark {
      background: #FFFFFF;
    }

    .new__other_filters {
      max-height: unset;
      flex-wrap: nowrap;
    }

    #close-filter {
      display: none;
    }

    .new__filter-bottom {
      bottom: -45px;
      padding-bottom: 25px;
      justify-content: center;
      flex-direction: column;
      left: 24px;
      right: 24px;

      .filter__block-formline.filter__block-formline--reset.text-right {
        padding-right: 0;
        width: 100%;
        text-align: center;
      }
    }
  }

  .new__filter #inner__filter-market {
    right: 0;
    text-align: left;
  }

  .active_filters {
    bottom: -22px;
    left: 36px;
    right: 36px;
    align-items: baseline;
    padding: 22px 36px;

    .right-block {
      flex-direction: column;

      .open-filters {
        margin: 10px 0 0 0;
      }
    }

    .center-block .active-filter {
      width: 100%;
      text-align: left;
    }
  }
  .new__filter #market-checkboxes #save-markets {
    right: 0;
    left: 0;
    bottom: -40px;
    text-align: center;
  }
}

@media (max-width: 559px) {
  .new__filter.active {
    #market-checkboxes {
      padding: 46px 20px 15px 15px;

      label {
        max-width: 220px;
        min-width: 220px;
      }
    }

    .max-w-226 {
      max-width: 218px;
    }
  }
  .active_filters {
    flex-direction: column;

    .right-block {
      width: 100%;

      .open-filters {
        width: 100%;
      }
    }

    .center-block {
      margin: 15px 0;
      width: 100%;

      .active-filter {
        padding-left: 20px;
        margin-right: 0;
      }
    }
  }
}
