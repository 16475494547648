.pagging {
  margin: 40px 0 0 0;
  padding: 40px 0 0 0;
  border-top: 2px solid $main-color;
  font: 20px/1.35 $font-europa;
  font-weight: bold;
  color: $gray-color;
  text-align: center;

  a {
    padding: 5px 10px;
    display: inline-block;

    &.active {
      color: $new-blue-color;
      font-size: 36px;
    }

    &.prev {
      width: 25px;
      height: 25px;
      outline: 2px solid $blue1-color;
      vertical-align: top;
      margin: 20px 10px 0 0;
      border-radius: 50%;
      background: url("../../images/icons/prev.svg") center center no-repeat;
      &:hover {
        outline: 3px solid $new-blue-darker-color;
      }
    }

    &.next {
      width: 25px;
      height: 25px;
      outline: 2px solid $blue1-color;
      vertical-align: top;
      margin: 20px 0px 0 10px;
      border-radius: 50%;
      background: url("../../images/icons/next.svg") center center no-repeat;
      &:hover {
        outline: 3px solid $new-blue-darker-color;
      }
    }

  }
}
