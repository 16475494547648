.cc-btn {
  position: relative;

  .btn-loader {
    opacity: 0;
  }

  .cc-btn__content,
  .btn-loader {
    transition: opacity .3s ease-in-out;
  }

  &.cc-btn--loading {
    .btn-loader {
      opacity: 1;
    }

    .cc-btn__content {
      opacity: 0;
    }
  }
}

.btn-loader {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 10px;
  width: 60px;
  transform: translate(-50%, -50%);
}

.btn-loader div {
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.btn-loader div:nth-child(1) {
  left: 6px;
  animation: btn-loader-dot1 0.6s infinite;
}

.btn-loader div:nth-child(2) {
  left: 6px;
  animation: btn-loader-dot2 0.6s infinite;
}

.btn-loader div:nth-child(3) {
  left: 26px;
  animation: btn-loader-dot2 0.6s infinite;
}

.btn-loader div:nth-child(4) {
  left: 45px;
  animation: btn-loader-dot3 0.6s infinite;
}

@keyframes btn-loader-dot1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes btn-loader-dot3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes btn-loader-dot2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
