.infoline {
  color: $white-color;
  background: $green-color;
  text-align: center;
  padding: 7px 0 7px;
  font-size: 1.1em;

  p {
    display: inline;
  }

  .container {
    padding-right: 40px;
    padding-left: 40px;
  }

  .btn {
    margin: 0 0 0 5px;
  }

  .close-x {
    position: absolute;
    top: 13px;
    right: 13px;
    width: 14px;
    height: 14px;
    display: inline-block;
    background: url("../../images/icons/close.svg") center center no-repeat;
  }

}

@media screen and (max-width: 991px) { /* 991-768 */

  .infoline {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    line-height: 2;
  }

}

@media screen and (max-width: 479px) {

  .infoline {
    line-height: 1.4em;

    h4,
    p{
      line-height: 1.4em;
      padding: 0 0 10px 0;
      display: inline-block;
    }

    h4{
      line-height: 1.2em;
    }
  }

}
