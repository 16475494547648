header {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  font-size: 0;
  background: $white-color;

  .logo {
    vertical-align: top;
    display: inline-block;
    font-family: $font-europa;
    font-weight: bold;
    letter-spacing: -0.01em;
    font-size: 16px;
    padding: 23px 40px 0 0;
    width: 19%;
  }

  .not-logo {
    vertical-align: top;
    display: inline-block;
    width: 81%;
  }

  .menu {
    width: 70%;
    vertical-align: top;
    display: inline-block;
    font-family: $font-europa;
    font-weight: bold;
    letter-spacing: -0.01em;
    font-size: 16px;
    padding: 78px 0 0 5px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;

        a {
          display: inline-block;
          padding: 8px 15px 12px 15px;

          &:hover {
            color: $green-color;
          }
        }

        &.active {
          a {
            color: $green-color;
          }
        }

      }

      &.mobile-only {
        display: none;
      }

    }
  }

  .search {
    display: none;
    float: left;
    margin: 6px 0 0 35px;
    width: 21%;
    height: 40px;
    background: $gray1-color;
    border-radius: 4px;

    input[type="text"] {
      float: left;
      width: calc(100% - 40px);
      border: none;
      font-size: 14px;
      padding: 10px 20px 10px;
      background: $gray1-color;
      border-radius: 4px;
    }

    input[type="submit"] {
      float: right;
      width: 40px;
      background: url("../images/icons/search.svg") center center no-repeat;
      background-size: 22px 22px;
      border: none;
      text-indent: -9999px;
      height: 40px;

      &:hover {
        background: url("../images/icons/search_g.svg") center center no-repeat;
        background-size: 22px 22px;
      }
    }
  }

  .info {
    width: 30%;
    vertical-align: top;
    display: inline-block;
  }

  .info__row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    @media screen and (max-width: 991px) and (min-width: 560px){
      position: fixed;
      top: 0;
      right: 85px;
    }

    @media screen and (max-width: 559px){
      justify-content: center;
    }
  }

  .info__auth {
    display: inline-block;
    float: right;
    padding: 18px 0 27px;
    font-size: 14px;

    .login {
      display: inline-block;
      position: relative;
      margin-top: 5px;

      .icon {
        height: 18px;
      }
    }

    .auth {
      display: inline-block;
      position: relative;

      ul {
        list-style: none;
        padding: 0;
        margin: -4px 0 0;
        text-align: center;
        background: $white-color;
        border-radius: 6px;
        transition: all .2s ease-out;

        li {
          display: none;
          border-top: 1px solid $gray1-color;

          &:first-child {
            border: none;
          }

          a {
            padding: 10px 15px 10px 15px;
            display: block;
            position: relative;
            z-index: 10;

            &:hover {
              color: $green-color;
            }
          }

          &.active {
            display: block;

            a:hover {
              color: $main-color;
            }
          }
        }
      }

      &:hover {
        ul {
          box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(0, 0, 0, 0.2);

          li {
            display: block;
          }
        }
      }
    }
  }

  .info__lang {
    display: inline-block;
    float: right;
    font-size: 14px;
    text-align: right;
    padding: 18px 0 27px;

    .lang {
      display: inline-block;
      margin: -4px -20px 0 20px;
      width: 95px;
      position: relative;
      vertical-align: top;
      text-transform: uppercase;
      z-index: 20;

      &:before {
        content: ' ';
        position: absolute;
        top: 10px;
        left: 15px;
        width: 20px;
        height: 20px;
        background: url("../images/icons/globe.svg") 0 center no-repeat;
        z-index: 5;
      }

      &:after {
        content: ' ';
        position: absolute;
        top: 17px;
        right: 20px;
        width: 8px;
        height: 5px;
        background: url("../images/icons/arrow_b_b.svg") 0 center no-repeat;
        z-index: 5;
      }

      ul {
        position: absolute;
        list-style: none;
        top: 0;
        right: 0px;
        padding: 0;
        margin: 0;
        width: 95px;
        text-align: center;
        background: $white-color;
        border-radius: 6px;
        transition: all .2s ease-out;

        li {
          display: none;
          border-top: 1px solid $gray1-color;

          &:first-child {
            border: none;
          }

          a {
            padding: 10px 5px 10px 15px;
            display: block;
            position: relative;
            z-index: 10;

            &:hover {
              color: $new-blue-color;
            }
          }

          &.active {
            display: block;

            a:hover {
              color: $main-color;
            }
          }

        }
      }

      &:hover {
        ul {
          box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(0, 0, 0, 0.2);

          li {
            display: block;
          }
        }
      }

    }

  }

  .btns {
    font-family: $font-europa;
    font-weight: bold;
    letter-spacing: -0.01em;
    font-size: 16px;
    text-align: right;
  }

  .mobile-menu {
    display: none;
  }

}

.fixed {
  header {
    position: fixed;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.03), 0px 4px 4px rgba(0, 0, 0, 0.05);
    z-index: 100;

    .logo {
      display: none;
    }

    .not-logo {
      width: 100%;
      min-height: 50px;
      position: relative;
    }

    .menu {
      width: auto;
      float: left;
      padding: 0px 0 0 0px;
      margin: 0px 0 0 -12px;
      font-size: 14px;

      ul li a {
        padding: 15px 12px 16px 12px;
      }
    }

    .search {
      display: block;
    }

    .info {
      width: auto;
      float: right;
    }

    .info__lang {
      float: left;
      padding: 11px 18px 10px 0;
      margin-right: 0;

      .lang {
        display: none;
      }
    }

    .info__row {
      @media screen and (min-width: 992px) {
        position: absolute;
        top: -10px;
        right: 0;
      }
    }

    .btns {
      float: left;
      padding: 7px 0 7px 0;
    }

    .btn--start {
      font-size: 14px;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15), 0px 0px 5px rgba(0, 0, 0, 0.05);
      border-radius: 18px;
      padding: 8px 20px 8px 20px;
    }

    .btn--empty {
      font-size: 14px;
      padding: 8px 15px 8px 15px;
    }

  }
}

@media screen and (max-width: 1419px) { /* 1281-1419 */

  header .logo {
    padding: 23px 30px 0 0;
    width: 18%;
  }

  header .not-logo {
    width: 82%;
  }

  header .menu {
    width: 75%;
    font-size: 15px;
    padding: 78px 0 0 0px;
  }

  header .menu ul li a {
    padding: 8px 13px 12px 13px;
  }

  header .search {
    margin: 6px 0 0 25px;
  }

  header .search input[type="text"] {
    padding: 10px 15px 10px;
  }

  header .info {
    width: 25%;
  }

  .fixed header .menu {
    font-size: 13px;
    margin: 0px 0 0 -10px;
  }

  .fixed header .info__lang {
    float: left;
    padding: 11px 10px 10px 0;
  }

  .fixed header .menu ul li a {
    padding: 15px 10px 16px 10px;
  }

}


@media screen and (max-width: 1279px) { /* 1200-1280 */

  header .logo {
    width: 20%;
  }

  header .not-logo {
    width: 80%;
  }

  header .menu {
    width: 72%;
    font-size: 16px;
    padding: 36px 0 0 0px;
    text-align: center;
  }

  header .menu ul li a {
    padding: 8px 15px 12px 15px;
  }

  header .info {
    width: 28%;
  }

  header .search {
    position: absolute;
    right: 380px;
    top: 0;
    overflow: hidden;
    width: 40px;
    transition: all .2s ease-out;

    input[type="text"] {
      width: 0;
      padding-left: 0;
      padding-right: 0;
      transition: all .2s ease-out;
    }

    &:hover {
      width: 300px;

      input[type="text"] {
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
      }
    }

  }

  .fixed header .search {
    display: block;
  }

  .fixed header .menu {
    font-size: 14px;
  }

}

@media screen and (max-width: 1199px) { /* 992-1199 */

  header .info {
    width: 36%;
  }

  header .logo {
    width: 23%;
  }

  header .not-logo {
    width: 77%;
  }

  header .menu {
    width: 64%;
  }

  .fixed header .menu ul {
    margin: 0 -8px 0 0;
  }

  .fixed header .search {
    right: 380px;
    top: auto;
    bottom: 6px;
  }

}

@media screen and (max-width: 991px) { /* 991-768 */

  header {
    min-height: 66px;
    position: fixed;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.03), 0px 4px 4px rgba(0, 0, 0, 0.05);
    z-index: 100;
  }

  header .not-logo {
    display: none;
  }

  header .mobile-menu {
    display: block;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 40px;
    padding: 10px 0 0 0;
    cursor: pointer;

    span {
      height: 2px;
      display: block;
      background: $main-color;
      margin: 0 0 10px;
    }
  }

  header .logo {
    width: auto;
    padding: 10px 0px 10px 0;
    height: 66px;
    position: relative;
    z-index: 10;

    img {
      max-height: 100%;
    }
  }

  .fixed header .logo {
    display: inline-block;
  }

  .fixed header.active,
  header.active {
    .not-logo {
      display: block;
      position: fixed;
      top: 0;
      width: 100%;
      background: #fff;
      left: 0;
      padding: 100px 0 20px 0;
      height: 100%;
    }

    .menu {
      width: 100%;
      padding: 20px 5px 20px 5px;
      text-align: center;
      font-size: 16px;

      ul {
        margin: 0;
        display: none;

        li a {
          padding: 6px 15px 8px 15px;
        }

        &.mobile-only {
          margin: 0 0 20px;
          display: block;
        }

      }
    }

    .info {
      width: 100%;
      padding: 0 20px;
    }

    .info__lang {
      text-align: center;
      padding: 18px 0 10px;
      float: none;

      .lang {
        margin: 0;
        display: inline-block;
      }

    }

    .btns {
      text-align: center;
      padding: 20px 0 0px;
      float: none;

      .btn--empty {
        font-size: 16px;
        padding: 8px 20px 12px 20px;
      }

      .btn--start {
        font-size: 16px;
        border-radius: 21px;
        padding: 8px 30px 12px 30px;
      }
    }

    .search {
      display: none;
    }

  }

  .fixed header.active .info__lang .cart,
  header.active .info__lang .cart {
    position: fixed;
    z-index: 10;
    top: 21px;
    left: 120px;
  }

  .fixed header.active .info__lang,
  header.active .info__lang {
    margin: 0;
    display: block;
  }

  header .info__auth {
    display: block;
    float: none;
    padding: 18px 0 18px;
    text-align: center;
  }

  header .not-logo {
    display: block;
  }

  header .info__lang,
  header .menu {
    display: none;
  }

  header.active .info__lang,
  header.active .menu {
    display: block;
  }

  .fixed header .not-logo {
    min-height: 0;
  }

  .fixed header .search {
    display: none;
  }

}

@media screen and (max-width: 559px) {

  .fixed header .info__auth, .info__auth {
    position: static;
  }

  .info__cart {
    right: 100px;
  }

  header .info__auth {
    display: none;
  }

  header.active .info__auth {
    display: block;
  }

  header .info__notifications {
    right: 180px;
  }

  header .info__studies {
    right: 215px;
  }

  header .logo img{
    width: auto;
  }
}


.info__cart {
  display: inline-block;
  float: right;
  font-size: 14px;
  text-align: right;
  padding: 18px 0 27px;
  margin-right: 10px;

  @media screen and (max-width: 559px){
    position: fixed;
    top: 0;
    right: 85px;
  }

  .cart {
    display: inline-block;
    vertical-align: top;

    a {
      padding: 5px 0 5px 33px;
      display: inline-block;
      position: relative;
      background: url("../images/icons/cart.svg") 0 center no-repeat;

      span {
        display: block;
        position: absolute;
        top: 1px;
        left: 16px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: $green-color;
        font-size: 10px;
        letter-spacing: -0.01em;
        color: $white-color;
        text-align: center;
        line-height: 1.3;
      }

      &:hover {
        color: $green-color;
      }

    }
  }
}

.info__studies {
  float: right;
  padding: 20px 0 27px;
  margin-right: 10px;

  @media screen and (max-width: 991px) { /* 991-768 */
    display: block;
    padding: 0 0 10px;
    float: none;
    text-align: center;
    margin-right: 0;
    position: fixed;
    top: 17px;
    right: 345px;
  }
}

.info__notifications {
  float: right;
  padding: 20px 0 27px;
  margin-right: 10px;

  .notifications__holder {
    position: relative;
  }

  .notifications__count {
    position: absolute;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background: $green-color;
    right: 0;
    top: 0;
    color: $white-color;
    font-size: 10px;
    letter-spacing: -0.01em;
    line-height: 1.3;
    text-align: center;
  }

  @media screen and (max-width: 991px) { /* 991-768 */
    display: block;
    padding: 0 0 10px;
    float: none;
    text-align: center;
    margin-right: 0;
    position: fixed;
    top: 17px;
    right: 310px;
  }
}
