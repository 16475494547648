.copyright {
  background: $gray5-color;
  border-radius: 6px;
  padding: 20px 50px;
  margin: 50px 0 80px;

  p {
    font-size: 16px;
    padding: 0;
  }
}
