.icon {
  display: inline-block;
  width: 19px;
  height: 20px;
  vertical-align: top;

  &--topic {
    background: url("../../images/icons/topic.svg") 0 center no-repeat;
    background-size: 10px 14px;
  }

  &--geography {
    background: url("../../images/icons/geography.svg") 0 center no-repeat;
    background-size: 13px 14px;
  }

  &--language {
    background: url("../../images/icons/language.svg") 0 center no-repeat;
    background-size: 13px 14px;
  }

  &--level {
    background: url("../../images/icons/level.svg") 0 center no-repeat;
    background-size: 12px 14px;
  }

  &--date {
    background: url("../../images/icons/date.svg") 0 center no-repeat;
    background-size: 12px 14px;
  }

  &--reset {
    background: url("../../images/icons/reset.svg") 0 center no-repeat;
    background-size: 14px 15px;
  }

  &--people {
    background: url("../../images/icons/people.svg") 0 center no-repeat;
    background-size: 12px 14px;
  }

  &--journal {
    background: url("../../images/icons/journal.svg") 0 center no-repeat;
    background-size: 15px 12px;
  }

  &--info {
    background: url("../../images/icons/info.svg") 0 center no-repeat;
    background-size: 15px 15px;
  }

  &--drop-down {
    background: url("../../images/icons/drop-down.svg") 0 center no-repeat;
    background-size: 15px 15px;
  }

  &--drop-down2 {
    background: url("../../images/icons/drop_down2.svg") 0 center no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }

  &--notify {
    background: url("../../images/icons/notify.svg") 0 center no-repeat;
    background-size: 14px 15px;
  }

  &--close {
    background: url("../../images/icons/close_b.svg") 0 center no-repeat;
    background-size: 14px 14px;
  }

  &--trash-alt-solid {
    background: url("../../images/icons/trash-alt-solid.svg") 0 center no-repeat;
    background-size: 14px 19px;
  }

  &--percentage-solid {
    background: url("../../images/icons/percentage-solid.svg") 0 center no-repeat;
    background-size: 14px 19px;
  }

  &--gmail {
    background: url("../../images/icons/gmail.svg") 0 center no-repeat;
    background-size: 14px 14px;
  }

  &--fb {
    background: url("../../images/icons/fb.svg") 0 center no-repeat;
    background-size: 14px 14px;
  }

  &--twitter {
    background: url("../../images/icons/twitter.svg") 0 center no-repeat;
    background-size: 14px 14px;
  }

  &--envelope {
    background: url("../../images/icons/envelope.svg") 0 center no-repeat;
    background-size: 14px 14px;
  }

  &--linkedin {
    background: url("../../images/icons/linkedin.svg") 0 center no-repeat;
    background-size: 14px 14px;
  }

  &--facebook {
    background: url("../../images/icons/facebook.svg") 0 center no-repeat;
    background-size: 14px 14px;
  }

  &--logout {
    background: url("../../images/icons/logout.svg") 0 center no-repeat;
    background-size: 16px 16px;
  }

  &--youtube {
    background: url("../../images/icons/youtube-gray.svg") 0 center no-repeat;
    background-size: 15px 15px;
  }

  &--file-download {
    background: url("../../images/icons/file-download.svg") 0 center no-repeat;
    background-size: 15px 15px;
  }

  &--pdf-download {
    background: url("../../images/icons/pdf-file.svg") 0 center no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
  }

  &--arrow-down {
    background: url("../../images/icons/arrow_b_b.svg") 0 center no-repeat;
    background-size: 15px 15px;
  }

  &--bell {
    background: url("../../images/icons/bell.svg") 0 center no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
  }

  &--share {
    background: url("../../images/icons/share.svg") 0 center no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
  }

  &--print {
    background: url("../../images/icons/print.png") 0 center no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
  }

  &--checkmark {
    background: url("../../images/icons/checkmark.png") 0 center no-repeat;
    background-size: 17px 15px;
    width: 17px;
    height: 15px;
  }

  &--crossmark {
    background: url("../../images/icons/crossmark.png") 0 center no-repeat;
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
  }

  &--subscription-minus {
    background: url("../../images/icons/sub-minus.png") 0 center no-repeat;
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
  }

  &--studies {
    background: url("../../images/icons/studies.png") 0 center no-repeat;
    background-size: 25px 25px;
    width: 25px;
    height: 25px;
  }

  &--eye {
    background: url("../../images/icons/eye-regular.svg") 0 center no-repeat;
  }
}

.icon--smaller {
  background-size: 8px 8px;
  background-position: 0 9px;
  width: 1rem;
}
