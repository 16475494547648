.author__picture {
  float: right;
  border-radius: 6px;
  padding: 0 !important;
  margin: 0 15px 50px 65px;
  max-width: 740px;
  max-height: 478px;
  @media screen and (max-width: 1069px) {
    width: auto;
    max-width: 100%;
    float: none;
    margin: 0 auto 50px;
    display: block;
  }
}

.author__contact {
  background-color: $gray5-color;
  display: flex;
  padding: 40px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 150%;
  max-width: 560px;
  @media screen and (max-width: 619px) {
    flex-direction: column;
    padding: 20px;

    .btn {
      margin-top: 15px;
    }

    br {
      display: none;
    }
  }
}

.auth__info {
  max-width: 560px;

  h1 {
    line-height: 1.35;
  }

  ul {
    margin: 0;

    li {
      padding: 11px 0 12px 0;
      border-top: 1px solid $gray1-color;

      &:first-child {
        border: none;
      }

      &:before {
        display: none;
      }

      span {
        font-size: 14px;
        background: $gray1-color;
        border-radius: 10px;
        color: $gray3-color;
        display: inline-block;
        padding: 0px 8px 2px;
      }

      .icon {
        width: 20px;
        height: 24px;
      }
    }
  }
}
