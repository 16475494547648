.user-info {
  .form-row {
    border-bottom: 1px solid $gray-color;
  }

  input {
    transition: border .3s ease-out;
  }

  @media screen and (max-width: 992px) {
    .form-horizontal .control-label {
      padding-top: 11px;
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .form-horizontal .control-label {
      padding-top: 9px;
    }
  }

  @media (min-width: 1201px) and (max-width: 1419px) {
    .form-horizontal .control-label {
      padding-top: 10px;
    }
  }

  @media (min-width: 1421px) {
    .form-horizontal .control-label {
      padding-top: 12px;
    }
  }
}

.user-info + .user-info {
  margin-top: 55px;
}

.profile_notification {
  padding: 5px 10px;

  &:not(:last-child) {
    border-bottom: solid 1px $gray1-color;
  }

  &.profile_notification--not-read {
    background: $gray1-color;
    font-weight: bold;
  }
}

@media screen and (max-width: 991px) {
  .profile_notification {
    padding: 5px 32px;
  }

  .user-info form input:not([type="checkbox"]), form select, form .form__input, form textarea {
    padding: 9px 30px 10px;
  }
}

@media screen and (max-width: 599px) {
  .profile_notification {
    padding: 5px 22px;
  }

  .user-info form input:not([type="checkbox"]), form select, form .form__input, form textarea {
    padding: 9px 20px 10px;
  }
}

