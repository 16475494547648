.cookie-agreement {
  width: 100%;
  min-height: 140px;
  position: fixed;
  bottom: 0;
  left: -100%;
  z-index: 500;
  transition: all .4s ease-out;
  background: #ffffff;
  padding: 20px 0 20px 0;
  color: #ffffff;
  box-shadow: 0 0.015px 5px 0 rgba(0, 0, 0, 0.24);
  opacity: 0;

  p {
    padding: 0;
  }

  &.cookie-agreement-open {
    left: 0;
    opacity: 1;
  }

  &.cookie-agreement-close {
    animation: slide-right 0.4s ease;
  }

  .cookie-agreement-holder {
    padding: 0 10px;
  }

  .row {
    margin-right: -5px;
    margin-left: -5px;
    display: flex;
  }

  .col-lg-10 {
    max-width: 83.333333%;
    width: 83.333333%;
  }

  .col-lg-4 {
    max-width: 30%;
  }

  .col-lg-2 {
    width: 16.666667%;
  }

  .agreement-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-height: 33px;
  }

  .agreement-title {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    color: #333;
    padding: 0;
  }

  .agreement-description {
    font-size: 12px;
    margin: 0;
    color: #777;

    a {
      font-size: 12px;
      color: #004990 !important;
    }
  }

  .toggleWrapper {
    input[type="checkbox"]:not(:checked),
    input[type="checkbox"]:checked {
      position: absolute;
      left: -9999px;
    }

    input[type="checkbox"]:not(:checked) + label {
      position: relative;
      padding-left: 30px;
      margin: 0;
      cursor: pointer;
    }

    input[type="checkbox"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0px;
      top: -2px;
      height: 20px;
      width: 20px;
      background-color: #ffffff;
      border-radius: 5px;
      border: 1px solid #E5E5E5;
      transition: 0.3ms all;
      //background: url("images/icons/unchecked.svg") center center no-repeat;
    }

    input[type="checkbox"]:not(:checked) + label,
    input[type="checkbox"]:checked + label {
      padding-left: 30px;
      margin: 0;
      position: relative;
      cursor: pointer;
    }

    input[type="checkbox"]:checked + label:before {
      content: '';
      position: absolute;
      left: 0px;
      top: -2px;
      height: 20px;
      width: 20px;
      border-radius: 5px;
      border: 1px solid #E5E5E5;
      transition: 0.3ms all;
      background-color: #78003F;
      //background: url("images/icons/checked.svg") center center no-repeat;
    }

    input[type="checkbox"]:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      left: 8px;
      top: 1.9px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(
          45deg
      );
      transform: rotate(
          45deg
      );
    }
  }

  .cookies-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .cookies-options-container {
    display: flex;
  }

  .cookies-btn-container {
    text-align: center;

    a {
      font-size: 14px;
      color: #fff;
      display: block;
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px;
      padding: 10px;
    }

  }

  #cookies-custom {
    font-size: 12px;
  }
}

#cookie-overlay {
  z-index: 499;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
  display: none;
  position: absolute;
  opacity: 0.5;
}

#cookie-primary {
  max-width: 100%;
  width: 1000px;
  min-height: 185px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: -100%;
  z-index: 500;
  transition: all .4s ease-out;
  background: #ffffff;
  padding: 20px;
  color: #ffffff;
  box-shadow: 0 0.015px 5px 0 rgba(0, 0, 0, 0.24);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.cookie-agreement-open {
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  .cookie-description {
    color: #000000;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }

  #cookies-all {
    margin: 0 auto;
  }

  #open-settings {
    text-decoration: underline;
  }

  #cookie-agreement-primary {
    display: flex;
    justify-content: center;
  }

  #cookies-all-primary {
    padding: 10px 51px;
    font-size: 12px;
  }
}

.cookie-agreement-wrap {
  max-width: 1330px;
  margin: 0 auto;
  padding: 0 15px;
}

.cookie-floater {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 70px solid transparent;
  border-left: 70px solid $blue1-color;
  border-bottom: 0 solid transparent;
  cursor: pointer;
  z-index: 400;
  transition: border-left-color 0.2s ease-out;

  &:hover {
    border-left: 70px solid $new-blue-darker-color;
  }
}

.cookie-floater .cookie-floater-icon {
  position: absolute;
  left: -60px;
  top: -30px;
  font-size: 20px;
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

@media (max-width: 1080px) {

  .cookie-agreement {

    .col-lg-10 {
      max-width: 100%;
      width: 100%;
    }

    .cookies-btn-container {
      display: flex;
      max-width: unset !important;

      a {
        margin: 0;
        margin-right: 10px;
        margin-bottom: 0 !important;
      }
    }

  }

  .cookie-agreement {

    .row {
      margin-right: 0;
      margin-left: 0;
      flex-direction: column;
    }

    .col-lg-4 {
      max-width: 100%;
    }

    .col-lg-2 {
      max-width: 100%;
      width: 100%;
    }

    .cookie-agreement-container {
      /*padding: 0 30px;*/
    }

    .agreement-description {
      margin: 0 0 15px;
    }
  }

  .cookie-agreement {
    padding: 10px 0 10px;

    .col-lg-2 {
      max-width: 100%;
    }

    .agreement-header {
      min-height: auto;
    }

    .agreement-title {
      margin: 0 0 5px;
      font-size: 12px;
      line-height: 1.3;
    }

    .agreement-description {
      margin: 0 0 5px;
    }

    #cookies-custom {
      padding: 7px 5px 5px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #cookies-all {
      padding: 7px 5px 5px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .cookie-agreement .col-lg-4,
  .cookie-agreement .col-lg-2 {
    padding: 0 0 10px;
  }
}
