// Colors
$main-color: #333333;
$gray4-color: #555555;
$gray3-color: #777777;
$gray-color: #BBBBBB;
$gray2-color: #E0E0E0;
$gray1-color: #F0F0F0;
$gray5-color: #F5F5F5;
$white-color: #FFFFFF;
$blue-color: #0061AF;
$blue1-color: #499EE9; // Same as $new-blue-color
$green-color: #3ABC98;
$red-color: #FF0000;
$error-color: $red-color;
$fb-color: #4968B4;

// New Colors
$new-blue2-color: #B7D5EB;
$new-blue-color: #499EE9; // Same as $blue1-color
$new-blue-darker-color: #1c86e3;
$new-blue-light-color: #E5EFF5;
$new-mask-color: #E0ECF5;
$new-black-color: #111B3F;

$new-input-color: #F2F9FD;
$new-filter-color: #DFEDF5;
