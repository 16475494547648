.multi-select {
  display: flex;
  min-height: 38px;
  background-color: #ffffff;
  border: 1px solid $gray2-color;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.multi-select--multiple {
  .multi-select__selection {
    padding: 3px;
    pointer-events: all;
  }
}

.multi-select__content {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
}

.multi-select__buttons {
  display: flex;
}

.multi-select__button {
  display: flex;
  align-items: center;
  padding: 8px;
  transition: background-color 250ms;

  &:hover {
    transition: background-color 250ms;
    background-color: #fafafa;
  }
}

.multi-select:hover .multi-select__button--root-hover {
  transition: background-color 250ms;
  background-color: #fafafa;
}

.multi-select__placeholder {
  color: #c3c3c3;
  padding: 10px;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.multi-select__selection {
  display: flex;
  padding: 9px 15px 10px;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-wrap: wrap;
  font-family: "TTRounds-Regular", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: normal;
}

.multi-select__arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #999999;
  transition: transform 250ms;
}

.multi-select__arrow--flip {
  transform: rotate(180deg);
  transition: transform 250ms;
}

.multi-select__search {
  flex: 1 !important;
  width: 100% !important;
  height: auto !important;
  margin: 9px 15px 10px !important;
  border: none !important;
  outline: 0 !important;
  padding: 0 !important;
  line-height: normal !important;

  &:focus {
    box-shadow: none !important;
    border: none !important;
  }
}

.multi-select-dropdown {
  max-height: 300px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #ffffff;
  z-index: 9999;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darken($blue-color, 10%);
  }

  &[x-placement="bottom-start"] {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 3px 6px rgba(179, 179, 179, .4);
  }

  &[x-placement="top-start"] {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 0 -2px 6px rgba(179, 179, 179, .4);
  }
}

.multi-select-dropdown__message {
  padding: 7px 24px;
  text-align: center;
  color: #c3c3c3;
  cursor: default;
  user-select: none;
}

.multi-select-group {

}

.multi-select-group__title {
  padding: 7px 12px;
  transition: background .3s;
  font-weight: bold;
  background-color: #fbfbfb;
  user-select: none;
  pointer-events: none;
}

.multi-select-item {
  padding: 7px 24px;
  transition: background .3s;

  &:not(disabled) {
    cursor: pointer;
  }

  &:hover:not(.multi-select-item--selected) {
    background-color: #fafafa;
  }
}

.multi-select-item--selected {
  background-color: #3f51b5;
  color: #fff;
}

.multi-select-tag {
  display: flex;
  background-color: #eee;
  color: #434343;
  border-radius: 3px;
  cursor: default;
  margin-right: 2px;
  transition: background-color 200ms;

  &:hover {
    transition: background-color 200ms;
    background-color: $blue-color;
    color: #fff;
  }
}

.multi-select-tag > span {
  padding: 5px 10px;
}

.multi-select-tag__button {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  transition: background-color 200ms;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    transition: background-color 200ms;
    background-color: darken($blue-color, 10%);
  }
}
