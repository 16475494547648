.rubrics-list {
  margin-bottom: 15px;

  a {
    &.active {
      color: $white-color;
      background: $green-color;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0), 0px 0px 5px rgba(0, 0, 0, 0);
    }
  }
}

.journal-info {
  float: right;
  height: 43px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 6px;

  @media screen and (max-width: 768px) {
    display: block;
    float: none;
  }

  a {
    white-space: nowrap;
    color: $blue1-color;

    &:hover {
      color: $green-color;
    }
  }

  a:not(:last-child) {
    margin-right: 15px;
  }
}

.courses-list:not(:last-child) {
  padding: 0 0 40px;
}

.courses-list__title {
  h2 {
    padding-bottom: 10px;
  }

  .courses-list__title {
    &--h1 {
      margin: -7px 0 0 0;

      .courses-list__title-info {
        padding-top: 22px;
      }
    }
  }
}

.courses-list__description {
  padding-bottom: 15px;
}

.courses-list__title-info {
  float: right;
  color: $gray-color;
  font-size: 16px;
  padding-top: 15px;
  padding-left: 10px;
}

.courses-list__items {
  display: flex;
  flex-wrap: wrap;
  margin: -6px -10px 10px;
  font-size: 0;
  min-width: 100%;
}

.courses-list__item-inner {
  border: 1px solid $gray1-color;
  border-radius: 6px;
  position: relative;
  transition: all .2s ease-out;
  background: $white-color;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.courses-list__item-inner--hover {
  display: none;
  background-color: $white-color;
  z-index: 10;
  left: 10px;
  top: 10px;
  right: 10px;
  width: initial;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25);
  transition: box-shadow 250ms;
  min-height: calc(100% - 20px);

  .img-responsive {
    width: 100%;
  }
}

.courses-list__item {
  display: flex;
  width: 25%;
  padding: 10px 16px;
  vertical-align: top;
  font-size: 14px;
  position: relative;
  z-index: 1;
  background: $white-color;
  border-radius: 6px;

  &:hover {
    z-index: 10;
    background: transparent;

    .courses-list__item-inner--hover {
      display: flex;

      .courses-list__item-desc {
        display: block;

        .ellipsis {
          display: none;
        }

        .courses-list__item-desc__more-content {
          display: initial;
        }
      }

      .courses-list__item-inner {
        min-height: 100%;
        box-shadow: 0 30px 60px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(0, 0, 0, 0.2);
        border-color: #d0d0d0;
        transition: box-shadow .2s ease-out, border .2s ease-out;
      }

      .courses-list__item-bottom {
        padding: 9px 30px 9px 30px;
      }

      .courses-list__item-text {
        transition: none;
      }

      .courses-list__item-categories {
        display: block;
      }
    }
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 10;
  }

}

.force-full-width {
  width: 100%;
}

.courses-list__items--diff {
  .courses-list__item {
    min-height: 277px;
  }
}

.courses-list__item-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px 0 0;

  img {
    max-width: 25px;
    max-height: 30px;
    margin-left: 10px;
    border-radius: 0 !important;
  }
}

.courses-list__item-img {
  font-size: 12px;
  position: relative;
  z-index: 2;
  transition: all .2s ease-out;
  min-height: 25px;
  text-align: right;

  img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 100%;
  }

  span {
    text-transform: uppercase;
    color: $white-color;
    background: $blue1-color;
    display: inline-block;
    padding: 2px 6px 2px;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    left: 20px;
  }

  .video-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
  }

}

.courses-list__item-text {
  padding: 14px 20px 9px 20px;
  position: relative;
  z-index: 3;
  transition: all .2s ease-out;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  flex: 1;

  h5 {
    padding: 0 0 5px;

    &.gray {
      color: $gray-color;
    }

    &.green {
      color: $green-color;
    }

    & ~ .courses-list__item-date {
      margin-top: -5px;
    }
  }

  p {
    padding: 4px 0 7px;
    color: $gray3-color;
  }

}

.courses-list__item-date {
  font-size: 0.875em;
  color: $gray-color;
}

.video-courses-list__item-date-duration {
  font-size: 0.875em;
  color: $gray-color;
  display: flex;
  justify-content: space-between;
  padding: 7px 0 5px 0;
}

.courses-list__item-desc {
  padding: 3px 0 3px;
  overflow: hidden;

  p {
    font-size: 12px;
  }
}

.courses-list__item-desc__more-content {
  display: none;
}

.courses-list__item-bottom {
  height: 41px;
  padding: 9px 20px 9px 20px;
  border-top: 1px solid $gray1-color;
  transition: all .2s ease-out;
}

.courses-list__item-stars {
  float: left;
  color: $gray-color;
  font-size: 12px;
  margin: 2px 0 0 0;

  span {
    display: inline-block;
    width: 61px;
    height: 18px;
    vertical-align: top;

    &.star-05 {
      background: url("../../images/icons/stars-05.svg") 0 0px no-repeat;
    }

    &.star-10 {
      background: url("../../images/icons/stars-10.svg") 0 0px no-repeat;
    }

    &.star-15 {
      background: url("../../images/icons/stars-15.svg") 0 0px no-repeat;
    }

    &.star-20 {
      background: url("../../images/icons/stars-20.svg") 0 0px no-repeat;
    }

    &.star-25 {
      background: url("../../images/icons/stars-25.svg") 0 0px no-repeat;
    }

    &.star-30 {
      background: url("../../images/icons/stars-30.svg") 0 0px no-repeat;
    }

    &.star-35 {
      background: url("../../images/icons/stars-35.svg") 0 0px no-repeat;
    }

    &.star-40 {
      background: url("../../images/icons/stars-40.svg") 0 0px no-repeat;
    }

    &.star-45 {
      background: url("../../images/icons/stars-45.svg") 0 0px no-repeat;
    }

    &.star-50 {
      background: url("../../images/icons/stars-50.svg") 0 0px no-repeat;
    }

    &.star--bigger {
      width: 80px;
      height: 21px;
      background-size: auto 120%;
      margin: -3px 8px 0 0;
    }

  }

}

.courses-list__item-rating {
  float: right;
}

.courses-list__item-categories {
  display: none;
  padding: 14px 31px 14px 31px;
  border-top: 1px solid $gray1-color;
  font-size: 12px;

  span {
    background: $gray1-color;
    border-radius: 9px;
    color: $gray3-color;
    display: inline-block;
    padding: 0 6px 2px;
    margin: 2px;
  }
}

.similar-list {
  .courses-list__item {
    width: 20%;
  }
}

@media screen and (max-width: 1419px) { /* 1281-1419 */

  .courses-list:not(:last-child) {
    padding: 0 0 60px;
  }

}

@media screen and (max-width: 1279px) {

  .courses-list__item {
    width: 33.3333%;
  }

  .similar-list .courses-list__item {
    width: 25%;
  }

}

@media screen and (max-width: 1199px) { /* 992-1199 */

  .courses-list__item-rating {
    font-size: 13px;
  }

}

@media screen and (max-width: 991px) { /* 991-768 */

  .courses-list__item-rating {
    font-size: 14px;
  }

  .similar-list .courses-list__item {
    width: 33.3333%;
  }

}

@media screen and (max-width: 767px) {

  .courses-list__item {
    width: 50%;
  }

  .similar-list .courses-list__item {
    width: 50%;
  }

}

@media screen and (max-width: 519px) {

  .courses-list__item {
    width: 100%;
  }

  .similar-list .courses-list__item {
    width: 100%;
  }

  .courses-list__item-text h5:first-child {
    min-height: 15px;
  }

}

@media screen and (max-width: 479px) {

  .courses-list__title-info {
    padding-top: 7px;
  }

}

@media screen and (max-width: 410px) {

  .courses-list__title-info {
    padding-top: 7px;
    display: block;
    float: left;
    margin: 30px -100% 22px 0;
  }

  .rubrics-list {
    margin-top: 15px;
  }

}

.courses-list__progress {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: #ffffff;
}

.courses-list__progress-completed{
  background: url("../../images/icons/check-solid.svg") center center no-repeat;
  width: 50%;
  height: 50%;
}
