form {
  label {
    font-family: $font-europa;
    letter-spacing: -0.01em;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 7px;
  }

  .checkboxes {
    padding: 5px 0 0 0;
    overflow: hidden;

    label {
      font-family: $font-europa;
      font-weight: normal;
      letter-spacing: 0;
      padding: 0 0 0 29px;
      position: relative;
      cursor: pointer;
      color: $gray3-color;

      input {
        display: none;
      }

      span {
        color: $gray3-color;
      }

      .checkmark {
        display: block;
        position: absolute;
        left: 0px;
        top: 0px;
        background: $gray5-color;
        border: 1px solid $gray2-color;
        border-radius: 3px;
        width: 18px;
        height: 18px;

        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 6px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid $main-color;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .radio {
        margin-top: 0;
        margin-bottom: 0;
        display: block;
        position: absolute;
        left: 0;
        top: 2px;
        background: $white-color;
        border: 1px solid $gray2-color;
        border-radius: 50px;
        width: 18px;
        height: 18px;

        &:after {
          content: "";
          position: absolute;
          display: none;
          width: 8px;
          height: 8px;
          top: 4px;
          left: 4px;
          border-radius: 50px;
          background-color: $main-color;
        }
      }

      input:checked ~ .radio:after {
        display: block;
      }

      input:checked ~ .checkmark:after {
        display: block;
      }

    }
  }

  .columns {
    margin: 0 -3px;

    .one-col {
      float: left;
      width: 50%;
      padding: 0 3px;
    }
  }

  input:not([type="checkbox"]), select, .form__input, textarea {
    font-size: 16px;
    font-family: $font-europa;
    font-weight: normal;
    border: 1px solid $gray2-color;
    border-radius: 4px;
    padding: 9px 15px 10px;
    width: 100%;

    &.form-control-plaintext {
      border-color: transparent;
    }

    &.datepicker {
      font-size: 14px;
    }
  }

  select.auto {
    display: inline-block;
    width: auto;
  }
}

.validation-error-label {
  color: $error-color;
}

@media screen and (max-width: 1419px) { /* 1281-1419 */
  form input[type="text"], .form__input, textarea {
    font-size: 15px;
  }

  form label {
    font-size: 15px;
  }
}

@media screen and (max-width: 1199px) { /* 992-1199 */
  form label {
    font-size: 14px;
  }

  form input[type="text"], .form__input, textarea {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) { /* 991-768 */
  form label {
    font-size: 16px;
  }

  form input[type="text"], .form__input, textarea {
    font-size: 16px;
  }
}

.form-group--password{
  position: relative;
  .password-eye{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}
