.cart-table {

  table tr td:last-child {
    border: 0;
  }
}

.cart__discount-info {
  color: $green-color;
  margin-top: 20px;
}

.cart__list {
  padding: 30px 40px 40px 40px;
  border-radius: 6px;
  background: $gray5-color;

  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.cart-empty {
  background-color: $gray5-color;
  border-radius: 6px;
  padding: 57px 15px 80px;
  text-align: center;

  img {
    padding-right: 4.5%;
    margin-bottom: 50px;
  }

  .btn {
    white-space: normal;
  }
}

.cart__checkout {
  padding: 30px 40px 40px 40px;
  border-radius: 6px;
  background: $gray5-color;

  p {
    font-size: 16px;
  }

  hr {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .form-group {
    margin: 0 0 5px;
  }

  input {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    padding: 9px 20px 10px;
    transition: all 0.2s;
    background-position: 20px center;
    background-repeat: no-repeat;
    text-indent: 30px;
    background-size: 12px 19px;

    &:focus {
      background-position: -20px center;
      text-indent: 0;
      padding-left: 20px;
    }

    &.discount-code {
      background-image: url("../images/icons/percentage-solid.svg");
    }
  }

  button {
    height: 60px;
    font-size: 20px;
  }
}

.cart__checkout-total {
  font-size: 18px;
  color: #777777;
}

.cart__checkout-apply-discount {
  color: #0061AF;
  cursor: pointer;
}

.cart__checkout-discount-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart__remove-item {
  cursor: pointer;
}

.cart__container {
  hr {
    border-color: #E0E0E0;
  }
}

.cart__items {
  padding-right: 20px;
}

.cart__price-before-discount {
  font-size: 20px;
  text-decoration-line: line-through;
  color: $gray3-color;
  padding-right: 12px;
}

@media screen and (max-width: 1199px) {
  .cart__list {
    hr {
      margin-bottom: 45px;
    }
    h4 {
      font-size: 15px;
    }
  }

  .cart__checkout-title {
    margin-top: 40px;
  }

  .cart-remove {
    position: absolute;
    top: -30px;
    left: 0;
    width: 44px;
  }
}


@media screen and (max-width: 599px) {

  .cart-remove {
    left: 7px;
  }

  .cart__amount {
    text-align: center;
  }

  .cart__list {
    padding: 30px 20px 40px 20px;
  }

  .cart__checkout {
    padding: 30px 20px 40px 20px;
  }

  .cart__list .col-xs-1 {
    padding-left: 0;
  }
}
