.auth-modal {
  p {
    padding-bottom: 0;
    text-align: center;
    color: $gray3-color;

    a {
      color: $blue-color;

      &:hover {
        color: $green-color;
      }
    }
  }

  label p {
    text-align: left;
  }

  .close {
    top: 20px;
    right: 20px;
  }

  .modal-content {
    width: 440px;
  }

  .modal-body {
    padding: 50px 50px;
  }

  form {
    .form-group {
      margin: 0 0 5px;
    }

    input, #auth-newsletter-language {
      background-color: $gray5-color;
      transition: all 0.2s;
      background-position: 20px center;
      background-repeat: no-repeat;
      padding: 9px 20px 10px 0;
      text-indent: 50px;

      &:focus {
        background-position: -20px center;
        text-indent: 20px;
      }

      &.type-name {
        background-image: url("../../images/forms/person.svg");
      }

      &.type-company_name {
        background-image: url("../../images/forms/company_name.svg");
      }

      &.type-company_address {
        background-image: url("../../images/forms/company_address.svg");
      }

      &.type-company_code {
        background-image: url("../../images/forms/code.svg");
      }
    }

    input[type="password"],
    .form-group--password input{
      background-image: url("../../images/forms/password.png");
    }

    input[type="email"], #auth-newsletter-language {
      background-image: url("../../images/forms/email.png");
    }

    .empty {
      color: $gray3-color;
    }
  }

  .auth__nav {
    margin-bottom: 30px;
    border-bottom: 2px solid $gray1-color;
    width: 100%;
    display: inline-block;

    a {
      width: 50%;
      display: inline-block;
      float: left;
      text-align: center;
      padding: 15px 5px;
      font: 20px $font-europa;
      font-weight: bold;
      color: $gray1-color;
      position: relative;

      &.active {
        color: $green-color;

        &:after {
          content: ' ';
          background-color: $green-color;
          height: 4px;
          width: 100%;
          bottom: -1px;
          left: 0;
          position: absolute;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {

  .auth-modal {
    padding: 0;
  }

  .auth-modal .modal-content {
    max-width: 100%;
  }

  .auth-modal .modal-body {
    padding: 30px 30px;
  }

  .content .btn--facebook,
  .btn--facebook,
  .content .btn--google,
  .btn--google,
  .content .btn--login,
  .btn--login {
    padding: 12px 20px;
    min-height: 50px;
  }

}
