.fixed header.new__header {
  background: #FFFFFF;
  position: fixed;

  .search input[type="text"] {
    padding: 8px 20px 9px;
  }

  .new__menu {
    padding: 10px 0 10px 0;

    ul {
      padding: 0 0 0 0;
      white-space: nowrap;
    }
  }

  .search {
    margin-left: auto;
    margin-top: 10px;
    margin-right: 15px;
  }

  .new__user-info {
    padding: 0;
    margin-left: initial;

    .info_icon {
      padding-top: 25px;
    }

    .studies_icon {
      padding-top: 26px;
    }

    .info__notifications {
      padding: 24px 0 0 0;
    }
  }

  .cart_icon {
    padding: 0;
    margin-top: 16px;
  }

  .new__info__auth {
    padding: 26px 0 15px;
  }
}

header.new__header {
  background: unset;
  position: absolute;

  .new__menu .hide-on-desktop {
    display: none;
  }

  .container {
    width: 1380px;
  }

  .new__header-content {
    vertical-align: top;
    display: flex;

    .logo {
      padding: 32px 0 0 0;
      margin-right: 10px;
    }
  }

  .new__menu {
    vertical-align: top;
    display: inline-block;
    letter-spacing: -0.01em;
    font-weight: normal;
    padding: 40px 0 0 0;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 0;

      li {
        display: inline-block;
        font: 17px/26px $font-europa;

        //&:last-child a {
        //  padding: 8px 0 12px 11px;
        //  margin-right: -20px;
        //}

        a {
          display: inline-block;
          //padding: 8px 11px 12px 11px;
          padding: 0 11px 0 11px;

          &:hover {
            color: $new-blue-color;
          }
        }

        &.active {
          a {
            color: $new-blue-color;
          }
        }
      }

      &.new__mobile-only {
        display: none;
      }
    }
  }

  .new__user-info {
    display: flex;
    padding-top: 23px;
    justify-content: end;
    flex-shrink: 1;
    margin-left: auto;

    .info_icon {
      height: 20px;
      padding-top: 19px;
      padding-right: 15px;
    }

    .studies_icon {
      height: 20px;
      padding-top: 20px;
      padding-right: 15px;
    }

    .info__notifications {
      padding: 17px 0 0 0;

      .notifications__holder {
        i.icon.icon--bell {
          background: url("../images/icons/new_bell_icon.svg") 0 0 no-repeat;
        }
      }
    }
  }

  .cart_icon {
    display: inline-block;
    float: right;
    font-size: 14px;
    text-align: right;
    padding: 11px 0 0;
    margin-right: 10px;

    .cart {
      display: inline-block;
      vertical-align: top;

      a {
        padding: 6px 0 5px 33px;
        display: inline-block;
        position: relative;
        background: url("../images/icons/new_cart_icon.svg") 0 center no-repeat;
        font: 17px/26px $font-europa;

        span {
          display: block;
          position: absolute;
          top: -11px;
          left: 13px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          background: $new-blue-color;
          font-size: 12px;
          letter-spacing: 0.1em;
          color: $white-color;
          text-align: center;
          line-height: 1.8;
        }

        &:hover {
          color: $new-blue-color;
        }
      }
    }
  }

  .new__info__auth {
    display: flex;
    float: right;
    padding: 22px 0 27px;
    font-size: 14px;
    max-width: 120px;

    .auth {
      display: inline-block;
      position: relative;

      ul {
        list-style: none;
        padding: 0;
        margin: -4px 0 0;
        text-align: center;
        background: transparent;
        border-radius: 6px;
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;

        &.multi-word {
          margin: -15px 0 0;
        }

        li.active {
          display: block;
          font: 17px/26px $font-europa;
          font-weight: normal;
        }
      }
    }
  }

  .new__login__row {
    display: flex;

    .info__auth {
      display: inline-block;
      float: right;
      padding: 0;

      .login {
        display: flex;
        font: 20px/26px $font-europa;
        font-weight: normal;
      }
    }
  }

  .info__lang {
    padding: 14px 0;

    .lang:hover {
      ul {
        background: #FFFFFF;
      }
    }

    .lang:after {
      content: ' ';
      position: absolute;
      top: 17px;
      right: 33px;
      width: 16px;
      height: 9px;
      background: url("../images/icons/new_dropdown_arrow.svg") 0 center no-repeat;
      z-index: 5;
    }

    .lang li.active a {
      padding: 21px 0;
    }
  }

  .logout-icon {
    background: url("../images/icons/new_logout_icon.svg") 0 center no-repeat;
    background-size: 18px;
    height: 18px;
    width: 18px;
    display: block;
    margin-left: 10px;
  }

  .info__lang .lang ul {
    background: transparent;
  }

  // end of header.new__header
}

.new__btn {
  border-radius: 10px;
  padding: 12px 20px;
  margin: 0 8px;
  font-size: 18px;
  line-height: 26px;
  white-space: nowrap;
  font-weight: normal;
  display: inline-block;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &.blue {
    border: 1px solid $new-blue-color;
    background: $new-blue-color;
    color: $white-color;
    transition: background-color 0.5s;

    &:hover {
      background: $new-blue-darker-color;
    }
  }

  &.white {
    border: 1px solid $new-blue-color;
    background: transparent;
    color: $new-black-color;
    transition: all .2s ease-in;

    &:hover {
      box-shadow: 0 0 0 2px $new-blue-color;
    }
  }

  &.dark {
    border: 1px solid $new-black-color;
    background: $new-black-color;
    color: $white-color;
  }
}

.cart__hide {
  visibility: hidden;
}

@media (max-width: 1550px) {
  header.new__header .new__header-content .logo {
    margin-right: 10px;
  }
  //header.new__header .new__menu ul li a {
  //  padding: 8px 8px 12px 8px;
  //}
}

@media (max-width: 1380px) {
  header.new__header {
    .container {
      width: 100%;
    }
  }
}

@media (max-width: 1365px) {
  header.new__header .new__menu {
    font-size: 17px;
  }
}

@media (max-width: 1279px) {
  .fixed header.new__header {
    min-height: 80px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.05);

    .mobile-expand {
      margin-top: 33px;
      z-index: 60;
    }

    .new__header-content {
      padding-top: 0;

      .logo {
        display: block;
        padding: 20px 0 0 0;
      }
    }

    .search {
      right: 140px;
      top: 12px;
      z-index: 10;

      &.logged-in {
        right: 275px;
        top: 12px;
      }

      form input[type="submit"] {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .new__user-info {
      .info_icon {
        padding-top: 31px;
      }

      .studies_icon {
        padding-top: 32px;
      }

      .info__notifications {
        padding: 29px 7px 0 0;
      }

      .cart_icon {
        margin-top: 21px;
      }
    }
  }

  header.new__header {
    box-shadow: none;

    .new__menu ul li:last-child a {
      margin-right: 0;
    }

    .new__info__auth {
      display: none;
    }

    .new__user-info {
      .info__notifications {
        position: relative;
        padding: 17px 8px 0 0;
        top: unset;
        right: unset;
      }
    }

    .info__lang {
      display: none;
    }

    &.active {

      .new__menu {
        background: $new-blue-light-color;
        padding-top: 0 !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        overflow: auto;
        z-index: 49;
        padding-bottom: 50px;
      }

      .info__lang {
        display: none;
      }

      .hide-on-desktop {
        display: block;
      }

      .new__info__auth {
        padding-left: 60px;
        max-width: unset;

        .auth ul li a {
          color: $new-black-color;
          font: 20px/26px $font-europa;
        }
      }

      .requisites {
        display: block;
        z-index: 55;
        padding-left: 68px;

        &.logged-in {
          top: 545px;
        }

        #mobile-linkedin {
          display: block;
          width: 26px;
          height: 26px;
          background: url("../images/icons/new_linkedin_icon.svg") 0 0 no-repeat;
          margin-bottom: 20px;
        }

        .mobile-contacts a {
          display: block;
          font: 18px/28px $font-europa;
          padding: 1px 0;
        }
      }

      .new__menu {
        display: flex;
        flex-direction: column;

        .auth ul {
          display: block;
        }

        .info__lang {
          z-index: 56;
          text-align: left;
          padding-left: 60px;
          padding-top: 40px;

          .lang {
            margin: unset;

            &:after {
              right: 6px;
            }

            &:before {
              left: 7px;
            }

            &:hover {
              ul li {
                display: block;
              }
            }
          }

          ul {
            display: block;

            li a {
              font: 18px/36px $font-europa;
              color: $new-black-color;
              padding: 5px 0;

              &:hover {
                color: $green-color;
              }
            }

            li:not(.active) {
              display: none;
            }
          }
        }

        .new__login__row {
          padding: 14px 0 22px 60px;
          z-index: 55;

          .info__auth {
            float: left;
          }
        }

        ul {
          display: none;
        }

        .new__mobile-only {
          display: flex;
          flex-direction: column;
          background: $new-blue-light-color;
          padding: 109px 28px 0 60px;
          z-index: 50;

          li a {
            font: 24px/36px $font-europa;
            font-weight: bold;
            color: $new-black-color;
            width: 100%;
            padding: 8px 8px 6px 8px;
          }
        }
      }

      .mobile-expand {
        z-index: 55;

        a {
          background: url("../images/icons/new_close_mobile_menu_icon.svg") 0 0 no-repeat;
          display: block;
          height: 100%;
          width: 100%;
        }
      }
    }

    .mobile-expand {
      width: 15px;
      height: 15px;
      margin-top: 35px;
      position: absolute;
      right: 36px;

      a {
        background: url("../images/icons/new_mobile_expand_icon.svg") 0 0 no-repeat;
        display: block;
        height: 100%;
        width: 100%;
      }
    }

    .new__header-content {
      margin-left: 0;
      justify-content: space-between;
      padding-top: 15px;

      .logo {
        padding: 18px 0 0 0;
      }
    }

    .new__menu {
      display: none;
    }

    .new__user-info {
      display: flex;
      padding-top: 10px;
      margin-right: 0;
      z-index: 55;

      .new__login__row .info__auth {
        display: none;
      }
    }
  }
}

@media (max-width: 1199px) {
  header.new__header {
    .new__header-content .logo {
      margin-right: 15px;
    }
  }
}

@media (max-width: 1165px) {
  header.new__header .new__menu {
    padding: 26px 0 0 0;
  }
}

@media (max-width: 991px) {
  .fixed header.new__header {
    width: 100%;
    left: 0;
    right: 0;

    .new__header-content .logo {
      margin-left: 0;
    }

    .new__user-info {
      margin-right: 0;
    }

    .mobile-expand {
      right: 36px;
    }
  }
  header.new__header {
    width: unset;
    left: 0;
    right: 0;
    padding-left: 36px;
    padding-right: 36px;

    &.active .new__menu .info__lang {
      left: 26px;
    }

    .new__header-content {
      padding-top: 0;
      margin-left: 0;

      .logo {
        margin-left: -52px;
      }
    }

    .new__user-info {
      margin-right: -35px;
    }
  }

  .fixed header.new__header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 559px) {
  .fixed header.new__header {
    .new__header-content .logo {
      padding: 0;
      margin: 29px 0 0 0;
    }

    .new__user-info {
      .info_icon {
        padding-top: 33px;
      }

      .cart_icon {
        margin-top: 26px;

        &.logged-in {
          margin-right: 32px;
        }
      }

      .info__notifications {
        padding: 32px 4px 0 0;
      }

      .studies_icon {
        padding-top: 33px;
      }
    }
  }

  header.new__header {
    width: unset;
    left: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;

    .mobile-expand {
      right: 36px;
    }

    .cart_icon .cart a {
      font-size: 14px;
      line-height: 18px;
      background-size: 18px;
    }

    .new__header-content .logo {
      width: 148px;
      height: 25px;
      margin: 27px 0 0 0;
      padding: 0;
    }

    .new__user-info {
      padding-top: 14px;
      margin-right: 30px;

      .info__notifications {
        padding: 17px 4px 0 0;

        .notifications__holder i.icon.icon--bell {
          background-size: 16px;
          height: 19px;
          width: 19px;
        }
      }

      .studies_icon {
        padding-right: 7px;
        padding-top: 19px;

        a img {
          height: 16px;
        }
      }

      .info_icon {
        padding-right: 7px;
        padding-top: 19px;

        a img {
          height: 17px;
        }
      }
    }

    .cart_icon .cart a span {
      top: -5px;
      left: 12px;
      width: 17px;
      height: 17px;
      letter-spacing: 0;
      line-height: 1.5;
    }

    .container {
      padding-right: 36px;
      padding-left: 36px;
    }
  }
}

@media (max-width: 413px) {
  .fixed header.new__header {
    .new__header-content {
      padding-bottom: 15px;
    }

    .new__header-content .logo {
      margin: 55px 0 0 0;
    }

    .mobile-expand {
      margin-top: 60px;
    }

    .new__user-info {
      width: 100%;
      background: transparent;
      padding-right: 5px;

      .info_icon {
        padding-top: 24px;
      }

      .studies_icon {
        padding-top: 25px;
      }

      .info__notifications {
        padding: 24px 4px 0 0;
      }

      .cart_icon {
        margin-top: 19px;
      }
    }
  }
  .wrapper {
    padding: 120px 0 0 0;
  }
  header.new__header {
    left: 0;
    right: 0;

    .mobile-expand {
      right: 36px;
    }

    &.active {
      .new__menu .new__mobile-only li a {
        font: 20px/30px $font-europa;
      }

      .new__menu .new__login__row {
        top: 460px;
      }

      .new__menu .info__lang {
        top: 400px;
      }

      .new__info__auth {
        top: 438px;
      }

      .requisites {
        top: 540px;
        z-index: 55;

        &.logged-in {
          top: 510px;
        }
      }
    }

    .new__header-content {
      padding-top: 40px;
    }

    .new__user-info {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      min-width: unset;
      max-width: unset;
      width: 100%;
      background: #DDDEE2;
      height: 54px;
      justify-content: flex-end;
      padding-right: 5px;
    }
  }
}
