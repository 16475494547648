@import url("https://use.typekit.net/udn0chi.css");

@font-face {
  font-family: 'TTRounds-Regular';
  font-display: swap;
  src: url('../fonts/TTRounds-Regular.eot');
  src: url('../fonts/TTRounds-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTRounds-Regular.woff2') format('woff2'),
  url('../fonts/TTRounds-Regular.woff') format('woff'),
  url('../fonts/TTRounds-Regular.ttf') format('truetype'),
  url('../fonts/TTRounds-Regular.svg#TTRounds-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTRounds-Bold';
  font-display: swap;
  src: url('../fonts/TTRounds-Bold.eot');
  src: url('../fonts/TTRounds-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTRounds-Bold.woff2') format('woff2'),
  url('../fonts/TTRounds-Bold.woff') format('woff'),
  url('../fonts/TTRounds-Bold.ttf') format('truetype'),
  url('../fonts/TTRounds-Bold.svg#TTRounds-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTRounds-Light';
  font-display: swap;
  src: url('../fonts/TTRounds-Light.eot');
  src: url('../fonts/TTRounds-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/TTRounds-Light.woff2') format('woff2'),
  url('../fonts/TTRounds-Light.woff') format('woff'),
  url('../fonts/TTRounds-Light.ttf') format('truetype'),
  url('../fonts/TTRounds-Light.svg#TTRounds-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
$font-europa: "europa", Helvetica, Arial, sans-serif;

$font-light: "TTRounds-Light", Helvetica, Arial, sans-serif;
$font-regular: "TTRounds-Regular", Helvetica, Arial, sans-serif;
$font-bold: "TTRounds-Bold", Helvetica, Arial, sans-serif;
