.subscriptions-heading-row {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 991px) {
    padding-top: 30px;
  }
}

.subscriptions-heading {
  flex-grow: 1;
  display: flex;
}

.below-subscription-notice {
  max-width: 150px;
  margin: 0 auto;
  padding-top: 15px;
}

.subscribtions-table {
  display: flex;
  width: 100%;

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.subscribtions-table__mobile-only {
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.subscribtions-table-col {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .most-popular-ribbon {
    width: 110px;
    height: 110px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    right: -10px;

    &:before {
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      border: 5px solid #2980b9;
      border-top-color: transparent;
      border-right-color: transparent;
      top: 0;
      left: 0;
    }

    &:after {
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      border: 5px solid #2980b9;
      border-top-color: transparent;
      border-right-color: transparent;
      bottom: 0;
      right: 0;
    }

    span {
      position: absolute;
      display: block;
      width: 160px;
      padding: 5px 0;
      background-color: #3498db;
      box-shadow: 0 5px 10px rgba(0,0,0,.1);
      color: #fff;
      text-align: center;
      left: -14px;
      top: 27px;
      transform: rotate(45deg);
    }
  }

  @media screen and (max-width: 991px) {
    max-width: 280px;
    margin-bottom: 30px;
    &:first-child {
      display: none;
    }
  }

  &.subscribtions-table-col--highlighted {
    background-color: #EBFAFF;
    box-shadow: 20px 22px 24px rgba(23, 18, 63, 0.120028);
  }

  .subscribtions-table-col__row {
    padding: 15px 10px;

    &.subscribtions-table-col__row--no-padding {
      padding: 0;
    }

    &.subscribtions-table-col__row--heading {
      height: 130px;
    }

    &:not(:first-child):not(:last-child) {
      border-bottom: solid 1px;
    }
  }
}
