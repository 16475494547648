@import "~tippy.js/themes/light.css";

.tippy-tooltip {
  font-size: 1em;
  text-align: left;

  p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
