.cc-pdf {
  border: 1px solid $gray1-color;
  margin-bottom: 30px;
}

.cc-pdf__toolbar {
  background: rgba(0, 0, 0, .6);
  padding: 10px;

  a {
    color: #ffffff;

    &:hover {
      color: $green-color;
    }
  }

  .btn {
    color: #fff;
    background: transparent;
    border: solid 2px $blue1-color;
    border-radius: 23px;

    &:hover {
      outline: none;
      background: $green-color;
      border-color: $green-color;
    }
  }
}
