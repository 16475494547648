footer {
  margin: auto 0 0 0;
  color: $gray-color;
  font-size: 12px;
  padding-top: 66px;

  .footer-container {
    background: $new-black-color;
    padding: 80px 0 100px;
    .container {
      padding: 0 75px;
    }
  }

  a {
    color: $gray-color;

    &:hover {
      color: $new-blue-color;
    }
  }

  h6 {
    color: $gray-color;
  }

  p {
    padding: 0px 0 15px;
  }

  .links {
    float: left;
    width: 35%;

    ul {
      list-style: none;
      font-size: 0;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        width: 33.3333%;
        font-size: 12px;
        padding: 0 10px 0 0;

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(10) {
          width: 30%;
        }

        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(11) {
          width: 36.6666%;
        }

        a {
          padding: 2px 0 6px;
          display: inline-block;
        }
      }
    }
  }

  .center {
    float: left;
    width: 30%;
    padding: 0 5%;

    button {
      height: 40px;
      color: $gray2-color;
      border: none;
      background: $gray3-color;
      font-size: 14px;
      font-family: $font-europa;
      font-weight: bold;
      letter-spacing: -0.01em;
      border-radius: 4px;
      transition: all .2s ease-out;

      &:hover {
        background: $green-color;
      }
    }
  }

  .social {
    float: right;
    width: 15%;
    padding: 0 40px 0 0;

    a {
      display: inline-block;
      vertical-align: bottom;
      margin: 4px 12px 0 0;

      svg:not(.fill-style) {
        stroke: $gray-color;
      }

      svg.fill-style {
        fill: $gray-color;
      }

      &:hover {
        svg:not(.fill-style) {
          stroke: $new-blue-color;
        }

        svg.fill-style {
          fill: $new-blue-color;
        }
      }
    }
  }

  .contacts {
    float: right;
    width: 14%;
    padding: 0 40px 0 0;
  }

  .copy {
    float: right;
    width: 6%;
  }

  .solution {
    padding: 15px 0 0 0;

    a {
      margin: 8px 0 0 0;
      display: block;
      width: 73px;
      height: 20px;
      background: url("../images/cp.svg") 0 center no-repeat;

      &:hover {
        background: url("../images/cp_h.svg") 0 center no-repeat;
      }
    }
  }

}

@media screen and (max-width: 1419px) { /* 1281-1419 */

  footer {
    padding-top: 40px;

    .footer-container {
      background: $main-color;
      padding: 60px 0 60px;
    }
  }

  footer .center {
    padding: 0 3%;
  }

  footer .contacts {
    padding: 0 30px 0 0;
  }

  footer .social {
    width: 14%;
    padding: 0 10px 0 0;
  }

  footer .copy {
    width: 7%;
  }

}

@media screen and (max-width: 1279px) { /* 1200-1280 */

  footer .links ul li:nth-child(1),
  footer .links ul li:nth-child(4),
  footer .links ul li:nth-child(7),
  footer .links ul li:nth-child(10) {
    width: 50%;
  }

  footer .links ul li:nth-child(2),
  footer .links ul li:nth-child(5),
  footer .links ul li:nth-child(8),
  footer .links ul li:nth-child(11) {
    width: 50%;
  }

  footer .links ul li {
    width: 50%;
  }

  footer .links {
    width: 33%;
  }

  footer .center {
    width: 31%;
    padding: 0 5% 0 0;
  }

  footer .contacts {
    width: 15%;
  }

}

@media screen and (max-width: 1199px) { /* 992-1199 */

  footer .links {
    width: 35%;
  }

  footer .social {
    width: 10%;
  }

  footer .contacts {
    width: 16%;
  }

  footer .copy {
    width: 8%;
  }

  footer .social a {
    display: block;
    margin: 4px 0px 9px 0;
  }

  footer .links ul li a {
    padding: 2px 0 5px;
  }

}

@media screen and (max-width: 991px) { /* 991-768 */

  footer {
    padding-top: 30px;

    .footer-container {
      padding: 50px 0 50px;
    }
  }

  footer .links {
    width: 100%;
    padding: 0 0 20px;
  }

  footer .links ul li:nth-child(1),
  footer .links ul li:nth-child(4),
  footer .links ul li:nth-child(7),
  footer .links ul li:nth-child(10) {
    width: 33.3333%;
  }

  footer .links ul li:nth-child(2),
  footer .links ul li:nth-child(5),
  footer .links ul li:nth-child(8),
  footer .links ul li:nth-child(11) {
    width: 33.3333%;
  }

  footer .links ul li {
    width: 33.3333%;
  }

  footer .center {
    width: 50%;
  }

  footer .social {
    width: 14%;
  }

  footer .contacts {
    width: 25%;
  }

  footer .copy {
    width: 11%;
  }

}

@media screen and (max-width: 767px) {

  footer .center {
    width: 100%;
    padding: 0 0 20px 0;
  }

  footer .social {
    width: 33.3333%;
  }

  footer .contacts {
    width: 33.3333%;
  }

  footer .copy {
    width: 33.3333%;
  }

}


@media screen and (max-width: 479px) {
  footer .footer-container .container {
    padding: 0 40px;
  }

  footer .links ul li:nth-child(1),
  footer .links ul li:nth-child(4),
  footer .links ul li:nth-child(7),
  footer .links ul li:nth-child(10) {
    width: 50%;
  }

  footer .links ul li:nth-child(2),
  footer .links ul li:nth-child(5),
  footer .links ul li:nth-child(8),
  footer .links ul li:nth-child(11) {
    width: 50%;
  }

  footer .links ul li {
    width: 50%;
  }

  footer .copy {
    width: 25%;
  }

  footer .contacts {
    width: 47%;
  }

  footer .social {
    width: 28%;
  }

}
