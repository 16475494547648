.gallery{
  margin: -10px -10px 28px;
  font-size: 0;
}

.gallery-item{
  padding: 10px 10px 10px;
  display: inline-block;
  font-size: 16px;
  width: 20%;

  img{
    border-radius: 6px;
    transition: all .2s ease-out;
  }

  a:hover{
    img{
      box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(0, 0, 0, 0.2);
    }
  }

}

@media screen and (max-width: 1279px) { /* 1200-1280 */

  .gallery-item{
    width: 25%;
  }

}

@media screen and (max-width: 991px) { /* 991-768 */

  .gallery-item{
    width: 33.3333%;
  }

}

@media screen and (max-width: 599px) {

  .gallery-item{
    width: 50%;
  }

}

@media screen and (max-width: 410px) {

  .gallery-item{
    width: 100%;
  }

}
