.newsletter__holder {
  position: absolute;
  bottom: 0px;

  .row {
    display: table;
    height: 80px;
  }

  .newsletter__holder-text {
    display: table-cell;
    vertical-align: middle;

    p {
      padding-bottom: 0;
    }
  }

  .newsletter__holder-action {
    display: table-cell;
    vertical-align: middle;

    .form-group {
      margin-bottom: 0px;
    }
  }
}

.newsletter__holder-inner {
  background-color: $white-color;
  height: 80px;
  visibility: visible;
  opacity: 1;
  bottom: 0px;
  position: fixed;
  width: 100%;
  z-index: 990;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
  transition: background-color 300ms linear;
  border-top: 1px solid $gray1-color;
}

#toggle-newsletter {
  display: none;
  width: 100%;
  text-align: center;

  i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .arrow-down {
    display: none;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .arrow-up {
    display: none;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.active {
    .arrow-up {
      display: none;
    }

    .arrow-down {
      display: inline-block;
    }
  }
}

.newsletter__modal-input {
  width: 100%;
  max-width: 350px;
  display: inline-block;
  margin-right: 1em;
}

@media screen and (max-width: 991px) { /* 992-1199 */
  .newsletter__holder-inner {
    padding: 15px;
    height: auto;

    .row {
      display: block;
      height: auto;
    }

    .newsletter__holder-text {
      display: block;
      vertical-align: unset;
    }

    .newsletter__holder-action {
      display: block;
      vertical-align: unset;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  #toggle-newsletter {
    display: inline-block;

    .arrow-down {
      display: none;
    }

    .arrow-up {
      display: inline-block;
    }
  }
}

@media screen and (max-width: 460px) {
  .newsletter__holder {
    .columns {
      .one-col {
        width: 100%;

        .btn {
          margin-top: 10px;
        }
      }
    }
  }
}

.newsletter__close {
  position: absolute;
  right: 1em;
  top: 1em;
  cursor: pointer;
}
