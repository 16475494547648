.journal-release-file {
  letter-spacing: normal;
  a {
    color: $blue1-color;

    &:hover {
      color: $green-color;
    }
  }
}

.journal-release-file__holder {
  display: flex;
}
