.error-container {
  max-width: 992px;
  margin: 0 auto;
  padding: 30px 30px;

  a {
    color: $blue-color;

    &:hover {
      color: $green-color;
    }
  }
}

.error-search {
  display: block;

  #start-learning-form {
    border: 1px solid $gray-color;
    border-radius: 6px;
  }
}
