.content .btn,
.btn {
  position: relative;
  font-family: $font-europa;
  font-weight: bold;
  letter-spacing: -0.01em;
  transition: all .2s ease-out;
  outline: none;
  border: none;

  &:hover {
    color: $blue1-color;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
  }

  &--infoline {
    border-radius: 10px;
    color: $white-color;
    border: 1px solid $white-color;
    padding: 2px 24px 2px 12px;

    &:after {
      width: 6px;
      height: 10px;
      background: url("../../images/icons/arrow_w_r.svg") center center no-repeat;
      background-size: cover;
    }

    &:hover {
      background: $white-color;
      color: $green-color;

      &:after {
        background: url("../../images/icons/arrow_g_r.svg") center center no-repeat;
        background-size: cover;
      }
    }

  }

  &--all {
    border: 2px solid $blue1-color;
    border-radius: 10px;
    padding: 10px 40px 10px 20px;
    font-size: 14px;
    color: $main-color;
    white-space: normal;
    transition: all .2s ease-in;

    &:after {
      right: 14px;
      width: 8px;
      height: 14px;
      background: url("../../images/icons/arrow_b_r.svg") center center no-repeat;
      background-size: cover;
    }

    &:hover {
      box-shadow: 0 0 0 2px $blue1-color;
      color: $main-color;
    }

  }

  &--back {
    border: 2px solid $blue1-color;
    border-radius: 10px;
    padding: 10px 20px 10px 40px;
    font-size: 16px;
    color: $main-color;
    white-space: normal;
    margin: 0 0 50px;

    &:after {
      left: 14px;
      top: 14px;
      width: 8px;
      height: 14px;
      transform: rotate(180deg);
      background: url("../../images/icons/arrow_b_r.svg") center center no-repeat;
      background-size: cover;
    }

    &:hover {
      color: $white-color;
      background: $green-color;
      border-color: $green-color;

      &:after {
        background: url("../../images/icons/arrow_w_r2.svg") center center no-repeat;
        background-size: cover;
      }
    }
  }

  &--top {
    @extend .btn--back;

    background: none;
    padding: 0;
    border: none;

    &:after {
      position: static;
      transform: rotate(-90deg);
    }

    &:hover {
      background: none;

      &:after {
        background-image: url("../../images/icons/arrow_g_r.svg")
      }
    }
  }

  &--empty {
    border: 2px solid $blue1-color;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    font-size: 16px;
    color: $main-color;
    white-space: normal;
    margin: 0 0 50px;
    transition: all .2s ease-in;

    &:hover {
      box-shadow: 0 0 0 2px $blue1-color;
      color: $main-color;
    }
  }

  &--start {
    font-size: 16px;
    color: $white-color;
    background: $blue1-color;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15), 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 10px 30px 10px 30px;

    &:hover {
      color: $white-color;
      background: $new-blue-darker-color;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0), 0px 0px 5px rgba(0, 0, 0, 0);
    }
  }

  &--gray {
    font-size: 16px;
    color: $white-color;
    background: $gray-color;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15), 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 10px 30px 10px 30px;

    &:hover {
      color: $white-color;
      background: $gray3-color;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0), 0px 0px 5px rgba(0, 0, 0, 0);
    }
  }

  &--rubric {
    font-family: $font-europa;
    font-weight: normal;
    margin: 4px 2px;
    display: inline-block;
    color: $blue1-color;
    border: 1px solid $blue1-color;
    background: $white-color;
    border-radius: 4px;
    box-shadow: none;
    padding: 7px 15px;
  }

  &--cart {
    color: $blue1-color;
    padding: 0 0 0 32px;
    font-size: 16px;
    white-space: normal;
    text-align: left;

    //&:after {
    //  content: ' ';
    //  display: block;
    //  position: absolute;
    //  top: 50%;
    //  left: 0px;
    //  transform: translate(0, -50%);
    //  width: 24px;
    //  height: 23px;
    //  background: url("../../images/icons/cart2.svg") 0 0 no-repeat;
    //}

    &:hover {
      color: $green-color;
    }
  }

  &--watch {
    color: $blue1-color;
    padding: 0 0 0 32px;
    font-size: 16px;
    white-space: normal;
    text-align: left;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 24px;
      height: 23px;
      background: url("../../images/icons/heart.svg") 0 0 no-repeat;
    }

    &:hover {
      color: $green-color;
    }
  }

  &--large {
    font-size: 20px;
    padding: 16px 37px;
    border-radius: 50px;
  }

  &--login {
    font-size: 20px;
    padding: 18px 20px;
    min-height: 60px;
    width: calc(100% + 20px);
    border-radius: 10px;
    margin: 7.5px -10px;
  }

  &--google {
    font-size: 20px;
    width: calc(100% + 20px);
    background-color: $white-color;
    padding: 18px 20px;
    color: $main-color;
    min-height: 60px;
    border-radius: 10px;
    margin: 7.5px -10px;
    background-image: url("../../images/forms/google.svg");
    background-position: 30px center;
    background-repeat: no-repeat;
  }

  &--linkedin {
    font-size: 20px;
    width: calc(100% + 20px);
    background-color: $white-color;
    padding: 18px 20px;
    color: $main-color;
    min-height: 60px;
    border-radius: 10px;
    margin: 7.5px -10px;
    background-image: url("../../images/forms/linkedin.png");
    background-position: 30px center;
    background-repeat: no-repeat;
    background-size: 25px auto;
  }

  &--facebook {
    font-size: 20px;
    width: calc(100% + 20px);
    background-color: $fb-color;
    padding: 18px 20px;
    color: $white-color;
    min-height: 60px;
    border-radius: 10px;
    margin: 7.5px -10px;
    background-image: url("../../images/forms/fb.svg");
    background-position: 30px center;
    background-repeat: no-repeat;
  }

  &--link {
    padding: 0;
    color: $blue-color;
    font-family: $font-europa;
    font-weight: normal;

    &:hover {
      color: $green-color;
    }
  }

  &--forgot {
    padding: 0;
    color: $gray-color;
    font-family: $font-europa;
    font-weight: normal;

    &:hover {
      color: $green-color;
    }
  }

  &--report {
    padding: 10px;
    color: $white-color;

    &:hover {
      color: $white-color;
    }
  }
}

.back-button {
  background: url("../../images/icons/arrow_back.svg") 0 center no-repeat;
  background-size: cover;
  width: 28px;
  height: 28px;
  display: inline-block;
  transition: background-image 0.2s ease-in-out;

  &.back-button--top {
    transform: rotate(90deg);
    margin-bottom: 50px;
  }

  &:hover {
    background-image: url("../../images/icons/arrow_back_g.svg");
  }
}
