.toast__container {
  padding: 10px !important;

  .btn--cart {
    &::after {
      margin-left: 15px;
    }
  }

  .toasted {
    border-color: $blue1-color !important;
    background-color: white !important;
    color: black !important;
  }
}
