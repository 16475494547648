.content-holder {
  border-top: 1px solid $gray1-color;
  padding-top: 60px;
}

.content-text {
  padding-left: 5px;
}

.content {
  font-size: 16px;
  line-height: 1.5;
  padding: 0 0 10px;

  iframe {
    max-width: 100%;
  }

  img {

  }

  h1 {
    padding: 0 0 34px 0;
  }

  .secondary-title {
    line-height: 1.25;
    font-size: 22px;
    font-weight: bold;
    font-family: $font-europa;
  }

  h2 {
    padding: 4px 0 30px 0;
  }

  h4 {
    padding: 0 0 12px 0;
  }

  h5 {
    padding: 0 0 10px 0;
  }

  p {
    padding: 0 0 28px 0;
  }

  a {
    color: $blue-color;

    &:hover {
      color: $green-color;
    }
  }

  ul.unstyled {
    list-style: none;
    padding: 0;

    li.list__topics, li.list__tags {
      a span {
        color: $new-blue-color;

        &:hover {
          color: $green-color;
        }
      }
    }
  }

  ul:not(.unstyled):not([class^="splide"]) {
    list-style-position: inside;
    list-style-image: url("../../images/bullet.png");
    padding: 0;

    & > li > ul {
      list-style-image: url("../../images/empty_bullet.png");

      & > li > ul {
        list-style-image: url("../../images/square_bullet.png");
      }
    }
  }

  ol:not(.unstyled):not([class^="splide"]), ul:not(.unstyled):not([class^="splide"]) {
    margin: 0 0 28px;

    li {
      position: relative;
      border: none;
      padding: 0 0 10px 22px;

      @media screen and (max-width: 991px) {
        padding: 0 0 10px 33px;
      }

      @media screen and (max-width: 599px) {
        padding: 0 0 10px 22px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    li {
      > ul:first-child,
      > ol:first-child {
        padding-top: 10px;
      }
    }

    ol, ul {
      margin-bottom: 0;
    }
  }

  table {
    td, th {
      border: 1px solid #ccc;
      padding: .4rem;

      p {
        padding-bottom: 0;
      }
    }
  }

  .back-link {
    margin: 15px 0;
    display: inline-block;
  }
}

.journal-description {
  a {
    color: $blue1-color;

    &:hover {
      color: $green-color;
    }
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 10px 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 991px) { /* 991-768 */
  .content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-holder {
    padding-top: 40px;
    border: none;
  }

}

@media screen and (max-width: 479px) {

  .content .h1 {
    font-size: 29px;
    line-height: 1.0;
  }

  .content .secondary-title {
    font-size: 21px;
  }

  .content-holder {
    padding-top: 20px;
  }

}

.rounded_pannel {
  background: $gray1-color;
  border-radius: 6px;
  padding: 15px;
}


.collapsable-content {
  overflow: hidden;
  position: relative;

  &.active {
    overflow: initial;
  }

  &.collapsable-content--expandable {
    padding-bottom: 1.5em;
  }

  &.collapsable-content--expanded-mobile {
    @media screen and (max-width: 991px) {
      &.collapsable-content--expandable {
        padding-bottom: 0;
      }

      &:not(.active) {
        max-height: initial;
      }

      .collapsable-content__footer {
        display: none;
      }
    }
  }

  &:not(.active) {
    .collapsable-content__footer {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &.collapsable-content--event {
      max-height: 5.5em !important;

      .collapsable-content__footer:before {
        background: unset !important;
      }
    }

    &:not(.collapsable-content--no-fade) {
      max-height: 12em;

      .collapsable-content__footer:before {
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        display: block;
      }
    }

    &.collapsable-content--no-fade {
      max-height: 0;
    }
  }

  .collapsable-content__show-less .icon {
    transform: rotateX(180deg);
  }

  .collapsable-content__footer, .collapsable-content__header {
    text-align: center;
    width: 100%;

    a {
      display: inline-flex;
      align-items: center;
      color: inherit;
    }

    .collapsable-content__button {
      font-size: 16px;
      display: flex;

      .icon {
        margin-right: 5px;
      }

      &:hover .icon--drop-down2 {
        background-image: url("../../images/icons/drop_down2_hover.svg");
      }
    }

    &:before {
      content: '';
      height: 5em;
      width: 100%;
      display: none;
    }

    div {
      background: #ffffff;
    }
  }

  .collapsable-content__footer {
    position: absolute;
    left: 0;
    bottom: -10px;
  }
}

.journal-rubrics {
  display: flex;
  @media screen and (max-width: 991px) {
    display: block;
  }
}

.without-sidebar {
  max-width: 1096px;
  margin: 0 auto;
}

.limit-visible__show-less .icon {
  transform: rotateX(180deg);
}
