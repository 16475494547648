.organization-members-list {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.organization-member {
  padding-bottom: 15px;
}

.organization-member__title{
  display: flex;
  align-items: center;
}

.organization-member__circle {
  margin: 0 15px 0 0;
  border-radius: 50%;
  background-color: $green-color;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white-color;
  font-size: 26px;
  text-transform: uppercase;
  border: none;
}

.organization-member__name {
  font-size: 18px;
  font-family: $font-europa;
  font-weight: bold;
}

.profile-nav {
  list-style: none;
  display: flex;
  padding: 0;
  border-bottom: solid 1px $gray1-color;
  margin-bottom: 15px;
}

.profile-nav__item {
  padding: 5px 15px;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  background: $gray1-color;

  &:not(:first-child){
    margin-left: 5px;
  }

  &.profile-nav__item--active{
    background: $blue1-color;
    a{
      color: $white-color;
    }
  }
}
