.new__start-learning {
  padding: 59px 0 56px 58px;
  margin: 0 auto 80px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  color: $white-color;
  background: url("../../images/new_start_learning_banner.png") center right no-repeat, url("../../images/new_start_learning_element.png") center right no-repeat;
  background-color: $new-mask-color;
  background-size: 575px, 87%, cover;
  max-width: 1380px;
  min-height: 365px;
  width: 100%;
  position: relative;

  &.with-filters {
    margin: 0 auto 180px;
  }

  #mobile-filter-submit {
    display: none;
  }

  .new__start-learning-left {
    width: 50%;
    max-width: 925px;

    h1 {
      text-align: left;
      max-width: 468px;
      color: $new-black-color;
      font: 45px/53px $font-europa;
      font-weight: bold;

      span {
        color: $new-blue-color;
      }
    }

    p {
      font: 17px/20px $font-europa;
      color: $new-black-color;
      text-align: left;
      padding-top: 6px;
      padding-bottom: 32px;
    }

    .start-learning__search {
      margin-left: 0;
      padding: 0;
      background: none;
    }

    .start-learning__search-input {
      width: calc(100% - 141px);
      max-width: 480px;
      box-shadow: 5px 10px 30px rgba(32, 33, 36, 0.11);
      height: 50px;

      input[type="text"] {
        padding: 16px 19px 20px;
        border-radius: 10px;
        font: 18px/26px $font-europa;
      }
    }

    .start-learning__search-submit {
      width: 99px;

      input[type="submit"] {
        border: 1px solid $new-black-color;
        background: $new-black-color;
        color: $white-color;
        border-radius: 10px;
        padding: 5px 5px;
        margin: 0 11px;
        font: 17px/24px $font-europa;
        font-weight: lighter;
        white-space: nowrap;
        text-indent: unset;
        height: 50px;
        width: 84px;
      }
    }
  }

  .expand_filter {
    display: inline-block;
    max-width: 88px;
    margin-left: 0;
    text-align: center;
    position: absolute;

    a {
      font: 18px/24px $font-europa;
      text-decoration: underline;
    }

  }

  .cc-select {
    z-index: 10;
  }

  #toggle_filter_mobile {
    display: none;
  }

  #close-filter-mobile {
    display: none;
  }

  // .new__start-learning END
}

@media (max-width: 1550px) {
  .new__start-learning .new__start-learning-left {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .new__start-learning {
    padding: 103px 36px 117px 36px;
    background: $new-blue-light-color;
    background-size: cover;
    max-width: 1796px;
    min-height: 570px;
    margin-top: -67px;
    border-radius: 0;

    &.with-filters {
      margin: -67px auto 180px;
    }

    .start-learning__search {
      max-width: unset;
      position: absolute;
      left: 36px;
      right: 36px;
      bottom: -32px;
    }

    .new__start-learning-left {
      width: 100%;

      h1 {
        max-width: 676px;
        font: 64px/81px $font-europa;
        font-weight: bold;
      }

      p {
        font: 32px/41px $font-europa;
        padding-bottom: 58px;
      }

      .start-learning__search-input {
        width: 100%;
        max-width: unset;
        height: 71px;
        input[type="text"] {
          font: 24px/31px $font-europa;
        }
      }

      .start-learning__search-submit {
        position: absolute;
        width: 60px;
        right: 30px;

        input {
          display: none;
        }

        #mobile-filter-submit {
          display: inline-block;
          background: url("../../images/icons/new_search_logo.svg") center center no-repeat;
          background-size: 23px 24px;
          border: none;
          height: 71px;
        }
      }
    }

    .expand_filter {
      display: block;
      background: $new-blue2-color;
      margin-top: 15px;
      max-width: unset;
      margin-left: 0;
      width: 100%;
      text-align: left;
      border-radius: 10px;
      position: relative;
      z-index: 25;

      &.active {
        #close-filter-mobile {
          display: block;
          position: absolute;
          top: 50%;
          right: 60px;
          transform: translateY(-50%);
          font: 18px/23px $font-europa;
          font-weight: bold;

          &:after {
            position: absolute;
            content: url("../../images/icons/new_close_mobile_menu_icon.svg");
            top: 0;
            right: -25px;
          }
        }

        &:after {
          position: unset;
          content: '';
          top: unset;
          right: unset;
          transform: unset;
        }
      }

      &:after {
        position: absolute;
        content: url("../../images/icons/new_mobile_browse_icon.svg");
        top: 50%;
        right: 31px;
        transform: translateY(-50%);
        pointer-events: none;
      }

      #toggle_filter_mobile {
        color: $new-black-color;
        text-decoration: none;
        font: 24px/36px $font-europa;
        padding: 16px 36px;
      }
    }

    #toggle_filter {
      display: none;
    }

    #toggle_filter_mobile {
      display: block;
    }
  }
}

@media (max-width: 559px) {
  .new__start-learning {
    .new__start-learning-left {
      h1 {
        font: 48px/62px $font-europa;
        font-weight: bold;
      }

      p {
        font: 24px/36px $font-europa;
        font-weight: normal;
      }
    }

    .new__start-learning-left .start-learning__search-input input[type="text"] {
      font: 18px/28px $font-europa;
    }
  }
}
