.checkout__success-holder {
  max-width: 800px;
}

.chekout-container {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.checkout__summary {
  margin-left: 30px;
  width: 100%;
}

.checkout__col {
  max-width: 600px;
}

.order-summary {
  position: relative;
}

@media screen and (max-width: 991px) {
  .checkout__summary {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .chekout-container {
    flex-direction: column-reverse;
  }
}
