.pt-0 {
  padding-top: 0;
}

.w-100 {
  width: 100%;
}

.w-30 {
  width: 30%;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mt-10 {
  margin-top: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-15 {
  margin-left: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-10 {
  margin-bottom: 10px;
}

.col-centered {
  max-width: 1136px;
  margin: 0 auto;
}

.d-block {
  display: block;
}

.font-16 {
  font-size: 16px;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  align-items: center;
}

.flex-end {
  align-items: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-center {
  align-items: center;
}

.url-current {
  color: $new-blue-color;
}

.url-partial {
  //
}

.no-wrap {
  white-space: nowrap;
}

.form-horizontal .control-label.text-left,
.text-left {
  text-align: left;
}

.cart-loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid $green-color;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: $gray3-color;
  text-align: center;
  height: 1.5em;
  opacity: .5;

  &:before {
    content: '';
    background-color: $gray3-color;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: $gray3-color;

    padding: 0 .5em;
    line-height: 1.5em;
    color: $gray3-color;
    background-color: $white-color;
  }
}

.color-success {
  color: $green-color;
}

.color-danger {
  color: $red-color;
}

.color-blue {
  color: $blue1-color;
}

.color-muted {
  color: $gray4-color;
}

.font-normal {
  font-family: $font-europa;
  font-weight: normal;
}

.color-blue {
  color: $blue1-color;
}
