.modal {
  padding: 4em 1em 1em 1em;
  color: $text-color;

  .close {
    position: absolute;
    right: .5em;
    top: .5em;
    cursor: pointer;
    z-index: 50;
  }

  .modal-content {
    max-width: 780px;
    margin: 0 auto;
    padding-top: 1em;
  }
}

[open-modal] {
  cursor: pointer;
}

.event-form__modal {
  padding: 0 25px;

  button[type="submit"] {
    margin: 0;
  }

  .form-horizontal .form-group {
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
  }
}

.btn-loading {
  position: relative;
  color: $blue1-color !important;

  &:hover {
    color: $new-blue-darker-color !important;
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    top: 6px;
  }
}
