.cc-select {
  position: relative;

  &.cc-select--focused {
    input {
      border-radius: 4px 4px 0 0;
    }
  }
}

.cc-select__options {
  position: absolute;
  z-index: 15;
  width: 100%;
  list-style: none;
  padding: 10px;
  margin: 0;
  background: #fff;
  border: 1px solid $gray2-color;
  border-top: none;
  font-size: 14px;
  border-radius: 0 0 4px 4px;
  column-count: 6;
  grid-column-gap: 0;
  box-shadow: 0 1px 4px 0 rgba(37, 37, 37, 0.25);

  @media screen and (max-width: 1250px) {
    column-count: 5;
  }

  @media screen and (max-width: 850px) {
    column-count: 4;
  }

  @media screen and (max-width: 750px) {
    column-count: 3;
  }

  @media screen and (max-width: 600px) {
    column-count: 2;
  }

  @media screen and (max-width: 450px) {
    column-count: 1;
  }

  li {
    border-radius: 5px;
    padding: 5px 15px;
    break-inside: avoid-column;
    page-break-inside: avoid;

    &:hover,
    &.cc-select__option--hovered {
      background-color: $gray1-color;
      cursor: pointer;
    }

    &.cc-select__option--selected {
      background-color: $blue1-color;
      color: $white-color;
    }
  }
}
