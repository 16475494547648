@import "~sweetalert2/src/sweetalert2.scss";

.swal2-modal {
  font-size: 14px;
}

.swal2-popup {
  .swal2-styled.swal2-confirm {
    font-family: $font-europa;
    font-weight: bold;
    letter-spacing: -0.01em;
    transition: all .2s ease-out;
    outline: none;
    font-size: 16px;
    color: $white-color;
    background: $blue1-color;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15), 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 22px;
    padding: 10px 30px 10px 30px;

    &:hover {
      color: $white-color;
      background: $green-color;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0), 0px 0px 5px rgba(0, 0, 0, 0);
    }

    &:hover {
      background-color: $green-color;
      outline: none;
    }

    &:focus {
      outline: none;
    }
  }

  .swal2-styled.swal2-cancel {
    @extend .swal2-styled.swal2-confirm;
    background: $gray-color;
  }
}
