.side-menu {
  background: $gray1-color;
  border-radius: 6px;
  padding: 30px 30px;
  margin: 0 10px 0 0;

  ul {
    list-style: none;
    margin: 8px 0 0 0;
    padding: 0;

    li {
      font-family: $font-europa;
      letter-spacing: -0.01em;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.375;
      border-top: 1px solid $gray2-color;

      &:first-child {
        border-top: none;
      }

      a {
        display: inline-block;
        padding: 8px 0 10px;

        &:hover {
          color: $blue-color;
        }
      }

      &.active > a {
        color: $blue-color;
      }

      &.has-children a {
        padding-bottom: 0;
      }

    }

    ul {
      padding-left: 15px;
      margin: 0;

      a {
        padding: 4px 0 5px;
      }

      li {
        border: none;
      }
    }
  }
}

@media screen and (max-width: 991px) { /* 991-768 */

  .side-menu h3 {
    padding: 0;
    cursor: pointer;

    span {
      vertical-align: top;
      margin: 3px 0 0 10px;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../../images/icons/arrow_b_b.svg") center center no-repeat;
      background-size: 17px auto;
    }
  }

  .side-menu ul {
    margin: 23px 0 0 0;
    display: none;
  }

}

@media screen and (max-width: 599px) {

  .side-menu {
    padding: 20px 20px 20px;
  }

}


