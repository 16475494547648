.filter {
  padding: 10px 0 0 0;

  h3 {
    padding: 0 0 18px 0;
  }
}

.filter__block {
  margin: 15px 0 0 0;
  padding: 5px 20px 15px;
  background: $gray1-color;
  border-radius: 6px;

  h3 {
    display: none;
  }
}

.filter__block-formline {
  padding: 15px 0 20px;
  border-top: 1px solid $gray2-color;

  &:first-child {
    border-top: none;
  }

  &--reset {
    border-top: none;
    padding: 10px 0 10px;
  }

  label {
    display: block;
  }
}

@media screen and (max-width: 1199px) { /* 992-1199 */

  .filter__block {
    padding: 5px 15px 15px;
  }

}

@media screen and (max-width: 991px) { /* 991-768 */

  .filter > h3 {
    display: none;
  }

  .filter__block {
    margin: 0px 0 0 0;
    padding: 15px 20px 15px;
  }

  .filter__block h3 {
    display: block !important;
    padding: 0;
    cursor: pointer;

    span {
      vertical-align: top;
      margin: 3px 0 0 10px;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../../images/icons/arrow_b_b.svg") center center no-repeat;
      background-size: 17px auto;
    }
  }

  .filter__block form {
    display: none;
    padding: 15px 0 0 0;
  }

}

.filter-show-more {
  cursor: pointer;
  font-weight: bold;

  .filter-show-more__text {
    padding-left: 5px;
  }

  .icon {
    width: 15px;
    height: 15px;
    margin: 1px 0;
  }

  &.active .icon {
    transform: rotate(180deg);
  }
}
