@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

.splide__container {
  position: relative;
  box-sizing: border-box;
}

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: -webkit-max-content;
  width: max-content;
  will-change: transform;
}

.splide.is-active .splide__list {
  display: flex;
}

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}

.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0;
}

.splide {
  visibility: hidden;
}

.splide, .splide__slide {
  position: relative;
  outline: none;
}

.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}

.splide__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #999;
  animation: splide-loading 1s linear infinite;
}

.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  -webkit-user-select: none;
  user-select: none;
}

.splide--fade > .splide__track > .splide__list {
  display: block;
}

.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
}

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide--ttb > .splide__track > .splide__list {
  display: block;
}

.splide--ttb > .splide__pagination {
  width: auto;
}

.splide__arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background: transparent;
}

.splide__arrow svg {
  width: 2.5em;
  height: 2.5em;
  fill: $blue1-color;
  transition: fill .2s linear;
}

.splide__arrow:hover {
  cursor: pointer;
}

.splide__arrow:hover svg {
  fill: $green-color;
}

.splide__arrow:focus {
  outline: none;
}

.splide__arrow--prev {
  left: 0;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 0;
}

.splide__slider > .splide__arrows .splide__arrow--prev {
  left: -2.5em;
}

.splide__slider > .splide__arrows .splide__arrow--next {
  right: -2.5em;
}

.splide__pagination {
  position: absolute;
  z-index: 1;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
}

.splide__pagination__page {
  display: inline-block;
  width: 20px;
  height: 5px;
  background: #ccc;
  border-radius: 2.5px;
  margin: 3px;
  padding: 0;
  transition: all .2s linear;
  border: none;
}

.splide__pagination__page.is-active {
  background: $blue1-color;
}

.splide__pagination__page:hover {
  cursor: pointer;
  background: $green-color;
}

.splide__pagination__page:focus {
  outline: none;
}

.splide__progress__bar {
  width: 0;
  height: 3px;
  background: $blue1-color;
}

.splide {
  padding: 3em;
}

.splide__slide {
  border-radius: 4px;
}

.splide__spinner {
  border: 2px solid $blue1-color;
  border-left-color: transparent;
}

.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 3px solid #fff;
  cursor: pointer;
  opacity: .7;
  border-radius: 4px;
}

.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: $blue1-color;
  opacity: 1;
}

.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none;
}

.splide--rtl > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
  right: 1em;
  left: auto;
}

.splide--rtl > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide--rtl > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide--rtl > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide--ttb > .splide__arrows .splide__arrow, .splide--ttb > .splide__track > .splide__arrows .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide--ttb > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
  top: 1em;
}

.splide--ttb > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide--ttb > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
  top: auto;
  bottom: 1em;
}

.splide--ttb > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide--ttb > .splide__pagination {
  display: flex;
  flex-direction: column;
  bottom: 50%;
  left: auto;
  right: 1em;
  transform: translateY(50%);
}

.splide--ttb > .splide__pagination .splide__pagination__page {
  width: 5px;
  height: 20px;
}
