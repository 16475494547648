.about-content {
  font-family: $font-europa;
  font-weight: normal;

  .container {
    width: 1380px;
  }

  .hide-on-desktop {
    display: none;
  }

  .hide-on-mobile {
    display: block;
  }

  h2 {
    font: 64px/81px "europa";
    font-weight: 700;
    padding-bottom: 81px;
    color: $new-black-color;
  }

  .description {
    font: 32px/46px "europa";
    color: $new-black-color;

    &.small {
      font: 24px/31px "europa";
    }
  }

  h4 {
    font: 32px/46px "europa";
    font-weight: bold;
    padding-bottom: 30px;
  }

  #our_vision_img {
    background: url("../../images/new-vision-image.png") 0 0 no-repeat;
    background-size: contain;
    width: 492px;
    height: 515px;
  }
}

#playVideo {
  .modal-content {
    background: transparent;
    box-shadow: none;
    border: none;
    max-width: 100vw;
  }

  .modal-dialog {
    width: 1000px;
  }

  .modal-header {
    padding: 0;
    border-bottom: none;
  }
}

.how-it-started-container {
  padding-top: 269px;

  .col-md-7 {
    margin-top: 125px;
  }

  .description {
    max-width: 525px;
  }
}

.how-does-it-work-container {
  padding: 171px 0 184px 0;

  .col-md-4 {
    padding: 0 8px;
  }

  .how-does-it-work-item {
    background: $new-blue-light-color;
    height: 597px;
    border-radius: 10px;
    position: relative;
    padding: 45px 64px 64px 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .toggle-expand {
      display: none;
      width: 15px;
      height: 15px;
      background: url("../../images/icons/new_expand_icon.svg") 0 0 no-repeat;
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);

      &.open {
        background: url("../../images/icons/new_subtract_icon.svg") 0 0 no-repeat;
      }
    }

    &:not(.first) {
      &:after {
        height: 73px;
        width: 39px;
        content: url("../../images/icons/new_how_does_it_work_triangle.svg");
        position: absolute;
        left: -11px;
        top: 75px;
        z-index: 1;
      }
    }

    &:not(.last) {
      &:before {
        height: 47px;
        width: 27px;
        content: url("../../images/icons/new_how_does_it_work_arrow.svg");
        position: absolute;
        right: -23px;
        top: 88px;
        z-index: 2;
      }
    }

    .card-image {
      width: 100%;
      min-height: 194px;
      margin-bottom: 13px;
      transition: height, max-height, min-height, 0.2s;

      &.experts {
        background: url("../../images/new_experts.png") 0 0 no-repeat;
      }

      &.publishers {
        background: url("../../images/new_publishers.png") 0 0 no-repeat;
      }

      &.learners {
        background: url("../../images/new_learners.png") 0 0 no-repeat;
      }
    }

    .description.small {
      padding-right: 50px;
      transition: height, max-height, min-height, 0.2s;
    }

    .card-link {
      background: $new-black-color;
      color: #FFFFFF;
      border-radius: 10px;
      padding: 15px 40px;
      display: block;

      &.no-link {
        pointer-events: none;
      }

      .card-panel {
        display: flex;
        align-items: center;
      }

      .card-left {
        font-size: 32px;
        font-weight: bold;
        line-height: 41px;
        padding-right: 15px;
        float: left;
      }

      .card-right {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
      }
    }
  }
}

.testimonials-container {
  background: $new-blue-light-color;
  padding: 171px 0 122px calc((100vw - 1836px) / 2);

  .testimonials__list-title {
    color: $new-black-color;
    font: 64px/81px $font-europa;
    font-weight: bold;
    max-width: 540px;

    span {
      color: $new-blue-color;
    }
  }

  .splide {
    padding: 0;

    .splide__slide {
      .white-bg {
        background: #FFFFFF;
        border-radius: 0 30px 0 30px;
        padding: 35px;
      }

      .testimonial__requisites {
        padding: 48px 64px 0 64px;
        color: #FFFFFF;
        display: flex;

        .testimonial__name {
          color: $new-black-color;
          display: block;
          font: 16px/20px $font-europa;
          font-weight: bold;
        }

        .testimonial__position {
          color: $new-black-color;
          display: block;
          font: 16px/20px $font-europa;
        }

        img {
          width: 51px;
          height: 51px;
          border-radius: 10px;
          margin-right: 16px;
        }

      }

      .testimonial__title {
        font: 25px/35px $font-europa;
        font-weight: bold;
        color: $new-black-color;
      }

      .testimonial__content {
        p {
          font: 18px/27px $font-europa;
          font-style: italic;
        }
      }
    }
  }
}

#how-it-started {
  width: 600px;
  height: 538px;
  background: url("../../images/new_how_it_started.png") 100px 0 no-repeat, url("../../images/new_triangle_outline.svg") 0 0 no-repeat;
  background-size: 483px;
  position: relative;

  #play-video-btn {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:after {
      content: url("../../images/icons/new_play_video_btn_inside.svg");
      width: 29px;
      height: 46px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.our-mission-item {
  max-width: 414px;

  .description.small {
    max-width: 325px;
  }

  .icon {
    width: 73px;
    height: 73px;
    margin-bottom: 66px;

    &.player {
      background: url("../../images/icons/new_video_player_icon.svg") 0 0 no-repeat;
    }

    &.arrows {
      background: url("../../images/icons/new_arrows_up_icon.svg") 0 0 no-repeat;
    }

    &.globe {
      background: url("../../images/icons/new_ecology_icon.svg") 0 0 no-repeat;
    }
  }
}

.bg-detail {
  height: 640px;
  width: 640px;
  background: url("../../images/new_about_us_bg_detail.svg") 0 0 no-repeat;
  position: absolute;
  right: -250px;
  top: 39%;
  z-index: 0;
  transform: translateY(-50%);
}

.our-vision-container {
  padding-top: 115px;

  .col-md-7 {
    padding-left: 77px;
    max-width: 659px;
    margin-top: 125px;
  }
}

.our-mission-container {
  padding-top: 115px;
}

.about-page-container {
  min-height: 1080px;
  background: url("../../images/new_about_us_left_bg.png") left 0 no-repeat, url("../../images/new_about_us_image.png") right 249px top 173px no-repeat #E5EFF5;
  width: 100%;
  background-size: contain, 532px;
  margin-top: -133px;
  position: relative;
  padding-bottom: 115px;

  .middle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 560px;

    h1 {
      font: 88px/105px "europa";
      font-weight: 700;
      padding-bottom: 30px;
      color: $new-black-color;
    }

    p {
      font: 24px/31px "europa";
      font-weight: normal;
      color: #606060;
    }
  }
}

@media (max-width: 1796px) {
  .about-content {
    .testimonials-container {
      background: $new-blue-light-color;
      padding: 171px 0 122px 36px;
    }
  }
}

@media (max-width: 1700px) {
  .about-content {
    .container {
      width: 100%;
    }

    .about-page-container, .our-vision-container, .our-mission-container, .our-mission-container, .how-does-it-work-container, .how-it-started-container {
      padding-left: 60px;
      padding-right: 60px;
    }

    .testimonials-container {
      padding: 171px 0 122px 69px;
    }
  }
}

@media (max-width: 1550px) {
  .how-it-started-container .col-md-7 {
    margin-top: 100px;
  }
  .about-content {
    #how-it-started {
      width: 500px;
      height: 447px;
      background-size: 401px;
    }

    #our_vision_img {
      width: auto;
    }

    .how-does-it-work-container {
      h2 {
        padding-bottom: 46px;
      }

      .how-does-it-work-item {
        height: auto;
        padding: 25px;
      }
    }

    .about-page-container {
      min-height: 1080px;
      background: url("../../images/new_about_us_left_bg.png") left 0 no-repeat, url("../../images/new_about_us_image.png") right 100px top 173px no-repeat #E5EFF5;
      background-size: cover, 550px;
    }

    .middle {
      top: 40%;

      h1 {
        font: 68px/80px $font-europa;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1550px) {
  .our-vision-container .col-md-7 {
    margin-top: 75px;
  }
}

@media (max-width: 1250px) {
  .how-it-started-container .col-md-7 {
    padding-left: 150px;
  }
}

@media (max-width: 1099px) {
  .how-it-started-container .col-md-7 {
    margin-top: 10px;
  }

  .our-vision-container .col-md-7 {
    margin-top: 0;
  }

  #playVideo {
    .modal-dialog {
      width: 100%;
    }
  }
  .about-content {
    .about-page-container {
      min-height: 800px;
      background: url("../../images/new_about_us_left_bg.png") left 0 no-repeat, url("../../images/new_about_us_image.png") right 100px top 173px no-repeat #E5EFF5;
      background-size: cover, 440px;
    }

    #our_vision_img {
      height: 455px;
    }

    .how-it-started-container {
      padding-top: 150px;
    }
  }
}

@media (max-width: 991px) {
  .about-content {
    h2 {
      padding-bottom: 30px;
      font: 40px/51px $font-europa;
      font-weight: bold;
    }

    .about-page-container, .our-vision-container, .our-mission-container, .our-mission-container, .how-does-it-work-container, .how-it-started-container {
      padding-left: 0;
      padding-right: 0;
    }

    #our_vision_img {
      background: url("../../images/new-vision-image.png") center 0 no-repeat;
      background-size: contain;
    }

    .our-vision-container {
      padding-top: 60px;

      .description {
        font: 24px/36px $font-europa;
        font-weight: normal;
      }

      .col-md-7 {
        padding: 0 8px;
        text-align: center;
        max-width: 100%;
      }

      .col-md-5 {
        padding: 0 8px;
      }
    }

    .our-mission-container {
      text-align: center;
      padding-top: 60px;

      .splide {
        padding: 0;
      }

      .splide__arrows {
        display: none;
      }

      .splide__pagination {
        li button {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #E5E5E5;

          &.is-active {
            background: #898989;
          }
        }
      }

      .col-md-4 {
        padding: 0 8px;
      }

      .our-mission-item {
        max-width: 100%;
        padding-bottom: 40px;

        .icon {
          margin-bottom: 20px;
        }

        .description.small {
          max-width: 100%;
        }
      }
    }

    .bg-detail {
      display: none;
      //right: -90px;
      //top: 47%;
      //z-index: -1;
    }

    .testimonials-container {
      padding: 171px 0 122px 36px;

      .testimonials__list-title {
        font: 40px/51px $font-europa;
        font-weight: bold;
      }

      .splide {
        padding: 0;

        .splide__slide {
          .white-bg {
            padding: 35px;
          }

          .testimonial__requisites {
            padding: 32px 0 0 0;
          }
        }

        .splide__pagination {
          bottom: -2em;

          .splide__pagination__page {
            background: #898989;
            width: 10px;
            height: 10px;
            border-radius: 50%;

            &.is-active {
              background: #F6FBFE;
            }
          }
        }
      }
    }

    .subtract-on-mobile {
      max-height: 0;
      overflow: hidden;
      min-height: unset !important;
      padding: 0 !important;
      margin: 0 !important;
      transition: all 0.2s;
    }

    .how-does-it-work-container {
      padding: 35px 0 35px 0;

      h2 {
        text-align: center;
      }

      .how-does-it-work-item {
        margin-bottom: 16px;

        &.first h4 {
          padding-bottom: 30px;
          padding-right: 0;
        }

        h4 {
          font: 24px/36px $font-europa;
          font-weight: bold;
          padding-bottom: 0;
          padding-right: 100px;
        }

        .toggle-expand {
          display: block;
        }

        &:before, &:after {
          display: none;
        }
      }
    }

    .need-for-platform {
      padding-top: 80px;

      .splide {
        padding: 0;

        .splide__slide {
          border-bottom: 2px solid $new-blue-color;
          border-radius: 0;
        }

        .splide__arrow {
          display: none;
        }

        .need-for-platform__icon {
          height: 41px;
          width: 41px;
          margin-bottom: 31px;
        }

        .need-for-platform__details {
          font: 24px/36px $font-europa;
        }

        .splide__pagination {
          bottom: -4em;

          li {
            .splide__pagination__page {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #E5E5E5;

              &.is-active {
                background: #898989;
              }
            }
          }
        }
      }

      h2 {
        font: 40px/51px $font-europa;
        font-weight: bold;
        padding-bottom: 30px;
      }

      img {
        object-fit: cover;
        width: 100%;
        display: block;
        margin: 0 auto 49px auto;
      }
    }

    .container {
      padding-right: 36px;
      padding-left: 36px;
    }

    .hide-on-desktop {
      display: block;
    }

    .hide-on-mobile {
      display: none;
    }

    .about-page-container {
      min-height: 1080px;
      background: url("../../images/new_about_us_left_bg.png") left 0 no-repeat, url("../../images/new_about_us_image.png") center top 485px no-repeat #E5EFF5;
      background-size: cover, 300px;
    }

    .how-it-started-container {
      padding-top: 80px;

      .col-md-12 {
        img {
          object-fit: cover;
          width: 100%;
          display: block;
          margin: 0 auto 70px auto;
        }

        h2 {
          font: 40px/51px $font-europa;
          font-weight: bold;
          padding-bottom: 30px;
        }

        .about-content {
          font: 24px/36px $font-europa;
          padding-bottom: 44px;
        }

        .about-description {
          font: 18px/28px $font-europa;
        }
      }
    }

    .middle {
      top: 30%;

      h1 {
        font: 58px/70px $font-europa;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 559px) {
  .about-content {

    .testimonials-container {
      padding-top: 71px;

      .testimonials__list-title {
        max-width: 235px;
      }
    }

    .how-does-it-work-container {
      padding-bottom: 80px;
    }

    .bg-detail {
      background-size: 383px;
      right: -53px;
      top: 44%;
      height: 300px;
      width: 300px;
    }

    .about-page-container {
      background: url("../../images/new_about_us_image.png") center 525px no-repeat #E5EFF5;
      background-size: 290px;
      min-height: 900px;

      .middle {
        top: 160px;
        transform: none;
      }

      h1 {
        font: 48px/62px $font-europa;
        font-weight: bold;
      }

      p {
        font: 18px/28px $font-europa;
      }
    }
  }
}

@media (max-width: 479px) {
  .about-content {
    .our-vision-container {
      h2, .description {
        text-align: left;
      }
    }

    .our-mission-container {
      h2, .our-mission-item {
        text-align: left;
      }
    }

    .how-does-it-work-container {
      h2 {
        text-align: left;
      }
    }
  }
}
