.course {

}

.hide-on-desktop {
  display: none;
}

.course__video-bg {
  position: relative;
  margin: 0 0 50px;
}

.transparent-bg {
  background: none;
}

.course__video-holder {
  width: 64%;
  float: left;
}

.similar-entries-subtitle {
  font-size: 17px;
}

.course__video {
  position: relative;
  padding: 0 0 56%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.course__video-thumbnail {
  background-repeat: no-repeat;
  background-size: 99.5% 100%;
  background-position: center;
}

.video_thumbnail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preview-course-btn {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  #play_vimeo_button {
    background: url("../../images/icons/play.png") 59% 50% no-repeat, #ffffff;
    background-size: 50%;
    padding: 50px;
    border-radius: 50%;

    &:hover {
      background: url("../../images/icons/play-hover.png") 59% 50% no-repeat, $new-blue-darker-color;
      background-size: 50%;
      padding: 50px;
      border-radius: 50%;

      .preview-course-text {
        color: $new-blue-darker-color;
      }
    }
  }
}

.preview-course-text {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  text-align: center;
  padding-top: 0.5rem;
}

.preview-course-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(69, 69, 69, 1) 50%, rgba(0, 0, 0, 1) 100%);
  opacity: 0.5;
}

.quiz-holder {
  position: relative;
  background: #F5F5F5;
  padding-top: 56%;
}

.quiz-holder-outer-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.quiz-holder-content {
  overflow: auto;
  flex: 1;
  margin: 20px;
}

.quiz-header {
  padding: 20px 20px 0 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.quiz-answers-text {
  font-family: "TTRounds-Regular", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  position: relative;
  color: #777777;
}

.quiz-incorrect-text {
  font-family: "TTRounds-Regular", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  position: relative;
  color: red;
}

.quiz-bottom-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.course__nav-holder {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 36%;
  overflow-y: auto;
}

.course__nav {
  h3 {
    padding: 35px 40px 20px 40px;
  }
}

.course-review {
  display: flex;
  flex-direction: column;
}

.course-review-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.course-review-header__image {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.post__buy-choises-prices {
  width: 100%;
}

.course-review-details {
  margin-top: 10px;
  font-size: 14px !important;
  padding: 0 !important;
}

.course-review-header__image + .course-review-header__details {
  margin-left: 20px;
}

.course__nav-video-quiz-bg, .course__nav-section-quiz-bg {
  background-color: rgba(224, 224, 224, 0.5);;
  text-align: center;
  margin-left: 1px;
}

.course__nav-video-quiz, .course__nav-section-quiz {
  &:hover {
    background-color: rgba(224, 224, 224, 1);
  }
}

.course__nav-item, .course__nav-video-quiz, .course__nav-section-quiz {
  font-family: $font-europa;
  font-weight: bold;
  letter-spacing: -0.01em;
  font-size: 14px;
  line-height: 19px;
  border-bottom: 1px solid $gray2-color;

  &:last-child {
    border-bottom: 1px solid $gray5-color;
  }

  a {
    display: block;
    padding: 15px 40px;
    color: $main-color;
    position: relative;

    &:before {
      content: ' ';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translate(0, -50%);
    }

    &:hover {
      color: $main-color;
      background: rgba(224, 224, 224, 0.5);
    }

    &.played {

      &:before {
        background: url("../../images/icons/played.svg") center center no-repeat;
      }
    }

    &.active {
      background: rgba(224, 224, 224, 0.5);

      &:before {
        background: url("../../images/icons/play_small_black.svg") center center no-repeat;
      }
    }

    &.watched:not(.active) {
      &:before {
        background: url("../../images/icons/check-solid.svg") center center no-repeat;
      }
    }

    &.passed:not(.active) {
      &:before {
        background: url("../../images/icons/check-solid.svg") center center no-repeat;
      }
    }

    &.failed:not(.passed):not(.active) {
      &:before {
        background: url("../../images/icons/close_r.svg") center center no-repeat;
      }
    }

    &.active-quiz {
      background: rgba(224, 224, 224, 0.5);
    }

  }

  img {
    max-width: 70px;
    float: left;
    margin: 0 15px 0 0;
  }

  .time {
    float: right;
    margin: 0 0 0 20px;
    color: $gray-color;
  }

  .clear {
    display: block;
  }

}

.unclickable {
  pointer-events: none;
}

.course__tabs {
  padding: 0 0 20px;

  a {
    display: inline-block;
    padding: 10px 20px;
    margin: 0 10px 10px 0;
    background: $gray1-color;

    &.active {
      background: $gray2-color;
    }
  }
}

.course__tabs-info {
  display: none;
  max-width: 810px;

  &#tab-overview, &#tab-resourses {
    padding: 0 15px 15px;
  }
}

.course__tabs-info--active {
  display: block;
}

.video-course_paid-description {
  padding: 0 0 15px;
}

.additional-course-info__left {
  float: left;
}

.additional-course-info__right {
  float: right;
}

.additional-course-info-block {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}

.additional-course-info-block-info {
  margin-left: 0;
  float: right;

  .course__nav {
    max-height: 470px;
    overflow-y: scroll;
    margin: 0 10px 0 0;
  }
}


@media screen and (max-width: 991px) {
  .hide-on-desktop {
    display: block;
  }

  .col-35.additional-course-info__right {
    width: 100%;
  }

  .course__video-holder {
    width: 100%;
  }

  .course__video-bg {
    margin: 0;
  }

  .course__nav-holder {
    width: 100%;
    position: static;
  }

  .additional-course-info-block {
    flex-direction: column-reverse;
  }

  .additional-course-info-block-info {
    width: 100%;
    margin-left: 0;
  }

  .quiz-holder {
    min-height: 445px;
    padding-top: unset;
  }
}

.additional-course-info-block-info .post__buy-choises {
  padding: 50px 6%;

  p {
    margin: 0;
    padding: 0;
  }

  .post__buy-choises-info {
    padding: 0;
  }

  .post__buy-price {
    font-size: 36px;
  }

  .post__buy-old-price {
    font-size: 18px;
    padding-left: 0.5em;
    vertical-align: top;
  }

  .post__buy-choises-info-right {
    padding: 0;
    border-top: none;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    width: 100%;

    &.w-old-price {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 1240px) and (min-width: 992px) {
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 1240px) and (min-width: 992px), screen and (max-width: 516px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .post__buy-choises-prices {
    min-width: 100%;
    padding-bottom: 0px;

    &.w-old-price {
      width: 100%;
    }

    .btn {
      margin: 0;
      font-size: 16px;
      min-width: 100%;
      padding: 12px 22px 12px;
      font-weight: bold;
    }

    .form-element--radio {
      padding: 0;
    }
  }

  .post__buy-choises-links {
    padding-bottom: 15px;
    width: 100%;
  }

  @media screen and (max-width: 1226px) {
    .post__buy-choises-info-right .post__buy-choises-links {
      margin-top: 8px;
    }
  }
}

.register-to-view-course {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

@media screen and (max-width: 991px) {
  .post__buy-choises-prices {
    &.w-old-price {
      width: unset;
    }
  }

  .post__buy-choises-info-right {
    &.w-old-price {
      flex-wrap: unset;
    }
  }
}

