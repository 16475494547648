.event-list-item {
  display: flex;
  margin-bottom: 15px;

  .general-error {
    color: red;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  &__divider {
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px;
  }

  &.event-list-item--padded {
    padding-left: 15px;
  }

  .collapsable-content__show-less,
  .collapsable-content__show-more {
    text-align: left;
  }

  .collapsable-content__body {
    *:last-child {
      padding-bottom: 0;
    }
  }
}

.event-list-item__date {
  margin-right: 20px;
  min-width: 110px;

  p {
    padding: 0;
  }
}

.event-list-item__title {
  margin: 0 0 5px;
}

.event-list-date {
  padding-bottom: 10px !important;
}
