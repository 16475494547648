.report__holder {
  position: fixed;
  bottom: 150px;
  left: 0;
  z-index: 9000;
  background-color: $blue1-color;
  border-radius: 4px;
  padding: 3px;
  cursor: pointer;
  writing-mode: vertical-lr;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: $new-blue-darker-color;
  }
}

.report__form {
  textarea {
    resize: vertical;
  }
}
