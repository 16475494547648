.comments {
  margin-bottom: 50px;
  max-width: 850px;

  h2{
    padding-top: 10px;
  }

  textarea {
    resize: none;
    padding: 10px 15px;
    border: solid 1px $gray1-color;
    border-radius: 6px;
    width: 100%;
    margin-right: 15px;
  }

  .comment {
    position: relative;

    .comment {
      margin-right: -15px;
    }
  }
}

.comment-reply-btn {
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  border-radius: 2px;
  color: $blue-color;
  transition: color 120ms ease-in-out;
  padding: 0;

  &:hover {
    color: $green-color;
  }
}

.comment--reply {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
}

.comments-list {
  > .comment {
    margin-bottom: 30px;
  }
}

.comment__header {
  display: flex;
  justify-content: space-between;
}

.comments__submit {
  float: right;
  min-width: 120px;
  margin-top: 10px;
}

.comment_footer {
  margin-top: 10px;
}

.comment__delete {
  text-align: right;
  margin-top: -10px;

  a {
    color: $blue-color;

    &:hover {
      color: $green-color
    }
  }
}
