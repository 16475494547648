.search-list {
  padding: 0 0 40px;
}

.search-list__items {
  display: flex;
  flex-wrap: wrap;
  margin: -6px -10px 10px;
}

.search-list__item-inner {
  border-radius: 6px;
  position: relative;
  transition: all .2s ease-out;
  border: 1px solid $gray1-color;
  width: 100%;
}

.search-list__item {
  display: inline-flex;
  flex: 0 0 25%;
  width: 25%;
  vertical-align: top;
  padding: 10px;
  font-size: 14px;
  position: relative;
  z-index: 1;
  background: $white-color;

  &:not(.search-list__item--no-hover):hover {
    z-index: 10;
    background: transparent;

    .search-list__item-inner {
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25), 0px 5px 10px rgba(0, 0, 0, 0.2);
      transition: box-shadow .2s ease-out, border .2s ease-out;
    }

    .search-list__item-text {
      transition: none;
    }
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 10;
  }
}

.search-list__item-text {
  padding: 14px 20px 9px;
  background: $white-color;
  position: relative;
  z-index: 3;
  transition: all .2s ease-out;
  border-radius: 6px;
  font-size: 12px;

  h5 {
    padding: 0 0 5px;

    &.gray {
      color: $gray-color;
    }

    &.green {
      color: $green-color;
    }
  }

  p {
    padding: 0 0 7px;
    color: $gray3-color;
  }

}

.search-list__item-desc {
  padding: 3px 0 3px;
}

@media screen and (max-width: 1419px) { /* 1281-1419 */

}

@media screen and (max-width: 1280px) { /* 1200-1280 */

}

@media screen and (max-width: 1199px) { /* 992-1199 */

}

@media screen and (max-width: 991px) { /* 991-768 */
  .search-list__item {
    flex: 0 0 33.33333%;
    width: 33.33333%;
  }
}

@media screen and (max-width: 767px) {
  .search-list__item {
    flex: 0 0 50%;
    width: 50%;
  }
}

@media screen and (max-width: 519px) {

}

@media screen and (max-width: 479px) {

}

@media screen and (max-width: 415px) {
  .search-list__item {
    flex: 0 0 100%;
    width: 100%;
  }
}
